import { IonIcon, IonItem, IonList, IonButton, IonPopover } from '@ionic/react';
import { briefcaseOutline, exit } from 'ionicons/icons';
import Avatar from 'react-avatar';
import React, { useState, useContext } from 'react';
import { AppContext } from 'App';
import { UserRole } from 'contracts/contracts.shared';

export const TopMenu: React.FunctionComponent = () => {
  const appContext = useContext(AppContext);
  const defaultPopover = { visible: false, evt: undefined as any };
  const [popover, setPopover] = useState<{ visible: boolean; evt: any }>(defaultPopover);

  const onDismiss = () => {
    setPopover(defaultPopover);
  };

  const onClick = () => {
    setPopover(defaultPopover);
  };

  return appContext.user ? (
    <>
      <IonButton
        fill="clear"
        onClick={(e) => setPopover({ visible: true, evt: e.nativeEvent })}
        slot="end"
        className="topmenu-avatar"
        size="large"
      >
        <Avatar name={appContext.userSettings?.name || ''} size="32" round={true} maxInitials={2} color="#d61a7f" />
      </IonButton>

      <IonPopover isOpen={popover.visible} event={popover.evt} onDidDismiss={onDismiss}>
        <IonList>
          {appContext.userSettings?.role === UserRole.Admin && (
            <IonItem key="profile">
              <IonButton color="dark" fill="clear" onClick={onClick} routerLink="/settings/account">
                <IonIcon icon={briefcaseOutline} slot="start" />
                Account
              </IonButton>
            </IonItem>
          )}
          <IonItem key="logout" lines="none">
            <IonButton color="dark" fill="clear" onClick={onClick} routerLink="/signout">
              <IonIcon icon={exit} slot="start" />
              Logout
            </IonButton>
          </IonItem>
        </IonList>
      </IonPopover>
    </>
  ) : (
    <></>
  );
};
