import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  IonToolbar,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonFooter,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonRadioGroup,
  IonRadio,
  IonLoading,
  IonToggle,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonCheckbox,
  IonItemDivider,
  IonBadge,
  IonText,
  IonAvatar,
  IonDatetime
} from '@ionic/react';
import { toggleOutline, listOutline, listCircleOutline } from 'ionicons/icons';
import {
  InputChangeEventDetail,
  RadioGroupChangeEventDetail,
  TextFieldTypes,
  ToggleChangeEventDetail,
  SelectChangeEventDetail,
  TextareaChangeEventDetail,
  CheckboxChangeEventDetail,
  DatetimeChangeEventDetail
} from '@ionic/core';
import { ionContent } from '../common/helpers';
import Avatar from 'react-avatar';
import { DateTime } from 'luxon';
import IMask from 'imask';
import { OmitStrict } from 'contracts/contracts.shared';
import { ErrorBox } from './ErrorBox';
import { PhoneNumberType } from 'contracts/contracts.forms';

export enum FormMode {
  Create = 'create',
  Update = 'update'
}

interface BaseDefinition {
  name: string;
}

type LabelMode = 'top' | 'left' | 'hidden';
type IconMode = 'default' | 'hidden';
interface BaseLabeledFieldDefinition extends BaseDefinition {
  label: string;
  labelMode?: LabelMode;
  iconMode?: IconMode;
  icon?: any;
  indentLevel?: number;
}

export interface LabelFieldDefinition extends BaseLabeledFieldDefinition {}

export interface AlertHeaderFieldDefinition extends BaseDefinition {
  userName: string;
  userTitle: string;
  userOrganization: string;
  contactCount: number;
}

export interface DividerDefinition extends BaseDefinition {}

export interface SectionDefinition extends BaseDefinition {
  label: string;
  padTop?: boolean;
}

export interface DateFieldDefinition extends BaseLabeledFieldDefinition {}

export interface TimeFieldDefinition extends BaseLabeledFieldDefinition {}

export interface DateFieldValue {
  value: string;
  dateString?: string;
  isValid?: boolean;
}

export interface TimeFieldValue {
  value: string;
  timeString?: string;
  isValid?: boolean;
}

export interface CheckBoxFieldDefinition extends BaseLabeledFieldDefinition {
  checkboxLabel: string;
  enabled?: boolean;
}

export interface TextFieldDefinition {
  format: TextFieldTypes;
  name: string;
  label: string;
  required: boolean;
  enabled: boolean;
}
export interface TextFieldValue {
  value: string;
}

export interface PhoneNumberFieldDefinition extends OmitStrict<TextFieldDefinition, 'format'> {}
export interface PhoneNumberFieldValue {
  value: string;
  type: PhoneNumberType;
}

export interface TextAreaFieldDefinition {
  name: string;
  label: string;
  required: boolean;
  enabled: boolean;
  rows?: number;
  maxlength?: number;
  debounce?: number;
  autoGrow?: boolean;
}

export interface TextAreaFieldValue {
  value: string;
}

export interface SelectFieldDefinition extends BaseLabeledFieldDefinition {
  header: string;
  message: string;
  placeholder?: string;
  selectedText?: string;
}

export interface MultiSelectFieldDefinition extends SelectFieldDefinition {}

export interface SelectFieldItem {
  label: string;
  value: string;
  enabled: boolean;
}

export interface SelectFieldValue {
  value: string;
}

export interface MultiSelectFieldValue {
  values: string[];
}

export interface SwitchListFieldDefinition extends BaseLabeledFieldDefinition {}

export interface SwitchListFieldItem {
  value: string;
  label: string;
  enabled: boolean;
}

export interface SwitchListFieldItemValue {
  value: string;
  checked: boolean;
}

export interface SwitchListFieldValue {
  itemValues: SwitchListFieldItemValue[];
}

export interface RadioGroupFieldDefinition extends BaseLabeledFieldDefinition {
  allowEmptySelection?: boolean | undefined;
}

export interface RadioGroupFieldOption {
  label: string;
  value: string;
}

export interface RadioGroupFieldValue {
  selectedValue: string | undefined;
}

export interface CheckBoxFieldValue {
  checked?: boolean;
}
type FormFieldTypes =
  | 'text'
  | 'phoneNumber'
  | 'textArea'
  | 'date'
  | 'time'
  | 'radioGroup'
  | 'switchList'
  | 'select'
  | 'multiSelect'
  | 'checkBox'
  | 'label'
  | 'section'
  | 'divider'
  | 'alertHeader';

export type FormField =
  | { type: FormFieldTypes & 'text'; definition: TextFieldDefinition }
  | { type: FormFieldTypes & 'phoneNumber'; definition: PhoneNumberFieldDefinition }
  | { type: FormFieldTypes & 'textArea'; definition: TextAreaFieldDefinition }
  | { type: FormFieldTypes & 'checkBox'; definition: CheckBoxFieldDefinition }
  | { type: FormFieldTypes & 'radioGroup'; definition: RadioGroupFieldDefinition; options: RadioGroupFieldOption[] }
  | { type: FormFieldTypes & 'switchList'; definition: SwitchListFieldDefinition; items: SwitchListFieldItem[] }
  | { type: FormFieldTypes & 'select'; definition: SelectFieldDefinition; items: SelectFieldItem[] }
  | { type: FormFieldTypes & 'multiSelect'; definition: MultiSelectFieldDefinition; items: SelectFieldItem[] }
  | { type: FormFieldTypes & 'date'; definition: DateFieldDefinition }
  | { type: FormFieldTypes & 'time'; definition: TimeFieldDefinition }
  | { type: FormFieldTypes & 'label'; definition: LabelFieldDefinition }
  | { type: FormFieldTypes & 'divider'; definition: DividerDefinition }
  | { type: FormFieldTypes & 'section'; definition: SectionDefinition }
  | { type: FormFieldTypes & 'alertHeader'; definition: AlertHeaderFieldDefinition };

export interface FormSubmitResult {
  errors?: string[];
  onSuccess?: () => Promise<void>;
}

export interface FormProps<TValues> {
  parentContentId: string;
  className?: string;
  title: string;
  icon: any;
  fields: FormField[];
  values?: TValues;
  submitButtonText?: string;
  onSubmit?: (data: TValues) => Promise<FormSubmitResult>;
  onCancelRouterLink?: string;
  onFieldChanged?: (field: FormField, value: any, form: TValues) => Promise<void>;
}

const defaultFormInternalState = {
  isSubmitting: false,
  isSubmittable: true,
  isSuccess: false
};

export function Form<T extends { [key: string]: any }>(props: FormProps<T>) {
  const [formValues, setFormValues] = useState<T | undefined>(props.values);
  const { onSubmit, onCancelRouterLink, onFieldChanged } = { ...props };

  const [isSubmitting, setIsSubmitting] = useState(defaultFormInternalState.isSubmitting);
  const [isSubmittable, setIsSubmittable] = useState(defaultFormInternalState.isSubmittable);
  const [isSuccess, setIsSuccess] = useState(defaultFormInternalState.isSuccess);
  const [errors, setErrors] = useState<string[]>();

  const onFormSubmit = useCallback(
    async (evt: React.FormEvent<HTMLFormElement>) => {
      evt.preventDefault();
      if (onSubmit) {
        setIsSubmitting(true);
        setIsSubmittable(false);
        const result = await onSubmit(formValues as T);
        if (result.errors?.length) {
          setErrors(result.errors);
          setIsSubmitting(false);
          setIsSubmittable(true);
        } else {
          setIsSuccess(true);
          setTimeout(async () => {
            if (result.onSuccess !== undefined) {
              setIsSubmitting(defaultFormInternalState.isSubmitting);
              setIsSubmittable(defaultFormInternalState.isSubmittable);
              setIsSuccess(defaultFormInternalState.isSuccess);
              setErrors(undefined);
              await result.onSuccess();
            }
          }, 500);
        }
      }
    },
    [formValues, onSubmit]
  );

  const onTextFieldChange = useCallback(
    async (evt: CustomEvent<InputChangeEventDetail>) => {
      const element: HTMLInputElement = evt.target as HTMLInputElement;
      if (element) {
        let newForm: T = {} as T;
        const fieldValue: TextFieldValue = { value: element.value };
        setFormValues((s) => {
          newForm = { ...s, [element.name]: fieldValue } as T;
          setTimeout(async () => {
            const field = props.fields.find((f) => f.definition.name === element.name);
            if (field && onFieldChanged) {
              await onFieldChanged(field, fieldValue, newForm);
            }
          }, 10);
          return newForm;
        });
      }
    },
    [props.fields, onFieldChanged]
  );

  const onPhoneNumberFieldChange = useCallback(
    async (name: string, value: string, type: PhoneNumberType) => {
      let newForm: T = {} as T;
      const fieldValue: PhoneNumberFieldValue = { value, type };
      setFormValues((s) => {
        newForm = { ...s, [name]: fieldValue } as T;
        setTimeout(async () => {
          const field = props.fields.find((f) => f.definition.name === name);
          if (field && onFieldChanged) {
            await onFieldChanged(field, fieldValue, newForm);
          }
        }, 10);
        return newForm;
      });
    },
    [props.fields, onFieldChanged]
  );

  const onDateFieldChange = useCallback(
    async (evt: CustomEvent<DatetimeChangeEventDetail>) => {
      const element: HTMLIonDatetimeElement = evt.target as HTMLIonDatetimeElement;
      if (element) {
        let newForm: T = {} as T;
        // https://moment.github.io/luxon/docs/manual/formatting.html

        const dt = DateTime.fromISO(evt.detail.value ?? '');
        const fieldValue: DateFieldValue = {
          value: evt.detail.value ?? '',
          dateString: dt ? dt.toISODate() : '',
          isValid: dt.isValid
        };

        setFormValues((s) => {
          newForm = { ...s, [element.name]: fieldValue } as T;
          setTimeout(async () => {
            const field = props.fields.find((f) => f.definition.name === element.name);
            if (field && onFieldChanged) {
              await onFieldChanged(field, fieldValue, newForm);
            }
          }, 10);
          return newForm;
        });
      }
    },
    [props.fields, onFieldChanged]
  );

  const onTimeFieldChange = useCallback(
    async (evt: CustomEvent<DatetimeChangeEventDetail>) => {
      const element: HTMLIonDatetimeElement = evt.target as HTMLIonDatetimeElement;

      if (element) {
        let newForm: T = {} as T;
        // https://moment.github.io/luxon/docs/manual/formatting.html

        const dt = DateTime.fromISO(evt.detail.value ?? '');
        if (dt.isValid) {
          dt.set({ second: 0 });
        }
        const fieldValue: TimeFieldValue = {
          value: evt.detail.value ?? '',
          timeString: dt ? dt.toLocaleString(DateTime.TIME_24_SIMPLE as any) : '',
          isValid: dt.isValid
        };

        setFormValues((s) => {
          newForm = { ...s, [element.name]: fieldValue } as T;
          setTimeout(async () => {
            const field = props.fields.find((f) => f.definition.name === element.name);
            if (field && onFieldChanged) {
              await onFieldChanged(field, fieldValue, newForm);
            }
          }, 10);
          return newForm;
        });
      }
    },
    [props.fields, onFieldChanged]
  );

  const onTextAreaFieldChange = useCallback(
    async (evt: CustomEvent<TextareaChangeEventDetail>) => {
      const element: HTMLInputElement = evt.target as HTMLInputElement;
      if (element) {
        let newForm: T = {} as T;
        const fieldValue: TextAreaFieldValue = { value: element.value };
        setFormValues((s) => {
          newForm = { ...s, [element.name]: fieldValue } as T;
          setTimeout(async () => {
            const field = props.fields.find((f) => f.definition.name === element.name);
            if (field && onFieldChanged) {
              await onFieldChanged(field, fieldValue, newForm);
            }
          }, 10);
          return newForm;
        });
      }
    },
    [props.fields, onFieldChanged]
  );

  const onCheckBoxFieldChange = useCallback(
    async (evt: CustomEvent<CheckboxChangeEventDetail>) => {
      const element: HTMLIonCheckboxElement = evt.target as HTMLIonCheckboxElement;
      if (element) {
        let newForm: T = {} as T;
        const fieldValue: CheckBoxFieldValue = { checked: element.checked };
        setFormValues((s) => {
          newForm = { ...s, [element.name]: fieldValue } as T;
          setTimeout(async () => {
            const field = props.fields.find((f) => f.definition.name === element.name);
            if (field && onFieldChanged) {
              await onFieldChanged(field, fieldValue, newForm);
            }
          }, 10);
          return newForm;
        });
      }
    },
    [props.fields, onFieldChanged]
  );

  const onRadioGroupFieldChange = useCallback(
    async (evt: CustomEvent<RadioGroupChangeEventDetail>) => {
      const element: HTMLInputElement = evt.target as HTMLInputElement;
      const value: string | undefined = evt?.detail?.value;

      let newForm: T = {} as T;
      const fieldValue: RadioGroupFieldValue = { selectedValue: value };
      setFormValues((s) => {
        newForm = { ...s, [element.name]: fieldValue } as T;
        setTimeout(async () => {
          const field = props.fields.find((f) => f.definition.name === element.name);
          if (field && onFieldChanged) {
            await onFieldChanged(field, fieldValue, newForm);
          }
        }, 10);
        return newForm;
      });
    },
    [props.fields, onFieldChanged]
  );

  const onSwitchListFieldChange = useCallback(
    async (fieldName: string, evt: CustomEvent<ToggleChangeEventDetail>) => {
      if (!evt.detail) return;

      let newForm: T = {} as T;
      let fieldValue: any = {};
      setFormValues((s) => {
        const copy = { ...s } as T;
        const items = (copy[fieldName]['itemValues'] || []) as SwitchListFieldItemValue[];
        const item = [...items].find((i) => i.value === evt.detail.value);

        if (item) {
          item.checked = evt.detail.checked;
        } else {
          items.push({
            value: evt.detail.value,
            checked: evt.detail.checked
          });
        }
        fieldValue = { itemValues: items };
        newForm = { ...s, [fieldName]: fieldValue } as T;
        setTimeout(async () => {
          const field = props.fields.find((f) => f.definition.name === fieldName);
          if (field && onFieldChanged) {
            await onFieldChanged(field, fieldValue, newForm);
          }
        }, 10);
        return newForm;
      });
    },
    [props.fields, onFieldChanged]
  );

  const onSelectFieldChange = useCallback(
    async (fieldName: string, evt: CustomEvent<SelectChangeEventDetail>) => {
      if (!evt.detail) return;

      const fieldValue: SelectFieldValue = {
        value: evt.detail.value || ''
      };
      setFormValues((s) => {
        const newForm = { ...s, [fieldName]: fieldValue } as T;
        setTimeout(async () => {
          const field = props.fields.find((f) => f.definition.name === fieldName);
          if (field && onFieldChanged) {
            await onFieldChanged(field, fieldValue, newForm);
          }
        }, 10);
        return newForm;
      });
    },
    [props.fields, onFieldChanged]
  );

  const onMultiSelectFieldChange = useCallback(
    async (fieldName: string, evt: CustomEvent<SelectChangeEventDetail>) => {
      if (!evt.detail) return;

      const fieldValue: MultiSelectFieldValue = {
        values: (evt.detail.value as string[]) || []
      };
      setFormValues((s) => {
        const newForm = { ...s, [fieldName]: fieldValue } as T;
        setTimeout(async () => {
          const field = props.fields.find((f) => f.definition.name === fieldName);
          if (field && onFieldChanged) {
            await onFieldChanged(field, fieldValue, newForm);
          }
        }, 10);
        return newForm;
      });
    },
    [props.fields, onFieldChanged]
  );

  useEffect(() => {
    if (props.values && JSON.stringify(props.values).length === 2) {
      return;
    }
    setFormValues(props.values);
  }, [props.values]);

  useEffect(() => {
    if (props.parentContentId && errors?.length) {
      ionContent(props.parentContentId).scrollToTop();
    }
  }, [props.parentContentId, errors]);

  return (
    <>
      <IonLoading isOpen={formValues === undefined} spinner="dots" message="Loading" cssClass="custom-loading" />
      {props.fields !== undefined && formValues !== undefined && (
        <IonCard className={props.className}>
          <IonCardHeader color="primary">
            <IonCardTitle>
              <IonToolbar color="primary">
                {props.icon && <IonIcon icon={props.icon} size="large" slot="start" />}
                {props.title}
              </IonToolbar>
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonLoading
              isOpen={isSubmitting && !isSuccess}
              animated={true}
              spinner="dots"
              message="Saving Changes"
              cssClass="custom-loading"
            />

            <IonLoading isOpen={isSuccess} animated={false} spinner={null} message="Saved!" cssClass="custom-loading" />

            {errors?.length > 0 && <ErrorBox errors={errors} onClearErrors={() => setErrors([])} />}
            <form onSubmit={onFormSubmit}>
              <IonList>
                {props.fields.map(
                  (field: FormField, index: number) =>
                    (field.type === 'text' && (
                      <TextField
                        key={field.definition.name}
                        value={(formValues[field.definition.name] as TextFieldValue).value}
                        onChange={onTextFieldChange}
                        {...field.definition}
                      />
                    )) ||
                    (field.type === 'phoneNumber' && (
                      <PhoneNumberField
                        key={field.definition.name}
                        value={(formValues[field.definition.name] as PhoneNumberFieldValue).value}
                        type={(formValues[field.definition.name] as PhoneNumberFieldValue).type}
                        onChange={onPhoneNumberFieldChange}
                        {...field.definition}
                      />
                    )) ||
                    (field.type === 'textArea' && (
                      <TextAreaField
                        key={field.definition.name}
                        value={(formValues[field.definition.name] as TextAreaFieldValue).value}
                        rows={field.definition.rows}
                        onChange={onTextAreaFieldChange}
                        {...field.definition}
                      />
                    )) ||
                    (field.type === 'date' && (
                      <DateField
                        key={field.definition.name}
                        value={(formValues[field.definition.name] as DateFieldValue).value}
                        onChange={onDateFieldChange}
                        {...field.definition}
                      />
                    )) ||
                    (field.type === 'time' && (
                      <TimeField
                        key={field.definition.name}
                        value={(formValues[field.definition.name] as TimeFieldValue).value}
                        onChange={onTimeFieldChange}
                        {...field.definition}
                      />
                    )) ||
                    (field.type === 'label' && <LabelField key={field.definition.name} {...field.definition} />) ||
                    (field.type === 'section' && <Section key={field.definition.name} {...field.definition} />) ||
                    (field.type === 'divider' && <Divider key={field.definition.name} />) ||
                    (field.type === 'alertHeader' && (
                      <AlertHeaderField key={field.definition.name} {...field.definition} />
                    )) ||
                    (field.type === 'radioGroup' && (
                      <RadioFieldGroup
                        key={field.definition.name}
                        options={field.options}
                        selectedValue={(formValues[field.definition.name] as RadioGroupFieldValue).selectedValue}
                        onChange={onRadioGroupFieldChange}
                        {...field.definition}
                      />
                    )) ||
                    (field.type === 'checkBox' && (
                      <CheckBoxField
                        key={field.definition.name}
                        checked={(formValues[field.definition.name] as CheckBoxFieldValue).checked}
                        onChange={onCheckBoxFieldChange}
                        {...field.definition}
                      />
                    )) ||
                    (field.type === 'switchList' && (
                      <SwitchListField
                        key={field.definition.name}
                        items={field.items}
                        values={(formValues[field.definition.name] as SwitchListFieldValue).itemValues}
                        onChange={onSwitchListFieldChange}
                        {...field.definition}
                      />
                    )) ||
                    (field.type === 'select' && (
                      <SelectListField
                        key={field.definition.name}
                        items={field.items}
                        value={(formValues[field.definition.name] as SelectFieldValue).value}
                        onChange={onSelectFieldChange}
                        {...field.definition}
                      />
                    )) ||
                    (field.type === 'multiSelect' && (
                      <MultiSelectListField
                        key={field.definition.name}
                        items={field.items}
                        values={(formValues[field.definition.name] as MultiSelectFieldValue).values}
                        onChange={onMultiSelectFieldChange}
                        {...field.definition}
                      />
                    ))
                )}
              </IonList>
              <IonFooter>
                <IonToolbar>
                  <IonButton
                    type="button"
                    slot="end"
                    fill="clear"
                    color="dark"
                    disabled={!isSubmittable}
                    routerLink={onCancelRouterLink}
                  >
                    Cancel
                  </IonButton>
                  <IonButton type="submit" slot="end" disabled={!isSubmittable}>
                    &nbsp; {props.submitButtonText || '  Save  '}
                  </IonButton>
                </IonToolbar>
              </IonFooter>
            </form>
          </IonCardContent>
        </IonCard>
      )}
    </>
  );
}

interface AlertHeaderFieldProps extends AlertHeaderFieldDefinition {}

const AlertHeaderField: React.FC<AlertHeaderFieldProps> = React.memo((props) => {
  return (
    <>
      <LabeledField labelMode="top" label="From" iconMode="hidden" />
      <IonItem lines="none">
        <Indent level={1} />
        <IonAvatar className="thumbnail-32" slot="start">
          <Avatar name={'Geddy Lee'} size="32" round={true} maxInitials={2} />
        </IonAvatar>

        <IonText color="dark">
          <span className="badge-text">
            <strong>{props.userName}</strong>
          </span>
          <br />
          <span className="badge-text text-xs">
            {props.userTitle} at {props.userOrganization}
          </span>
          <br />
        </IonText>
      </IonItem>
      <Divider />
      <LabeledField labelMode="top" label="To" iconMode="hidden" />
      <IonItem lines="none">
        <Indent level={1} />
        <IonBadge color="red" className="text-lg contacts-badge">
          &nbsp;&nbsp;{props.contactCount} Contact{props.contactCount !== 1 && <>s</>}&nbsp;&nbsp;
        </IonBadge>
      </IonItem>
    </>
  );
});

interface LabelFieldProps extends LabelFieldDefinition {}

const LabelField: React.FC<LabelFieldProps> = React.memo((props) => {
  const defaultArgs = {
    labelMode: 'top' as LabelMode,
    iconMode: 'hidden' as IconMode,
    indentLevel: 0,
    icon: undefined as any
  };

  const args = { ...defaultArgs, ...props };
  return (
    <>
      <LabeledField
        labelMode={args.labelMode}
        label={args.label}
        iconMode={args.iconMode}
        icon={args.icon}
        indentLevel={args.indentLevel}
      />
    </>
  );
});

const Divider: React.FC = () => <IonItemDivider className="form-divider" />;

interface SectionProps extends SectionDefinition {}

const Section: React.FC<SectionProps> = (props: SectionProps) => (
  <IonItemDivider className={!props.padTop ? 'form-section-divider' : 'form-section-divider-pad-top'}>
    <IonLabel color="dark">{props.label}</IonLabel>
  </IonItemDivider>
);

interface TextFieldProps extends TextFieldDefinition {
  value: string;
  onChange: (evt: CustomEvent<InputChangeEventDetail>) => void;
}

const TextField: React.FC<TextFieldProps> = React.memo((props) => {
  return (
    <IonItem className="ion-item-pad-fix">
      {/* <IonIcon icon={pencil} slot="start" size="small" /> */}
      <IonLabel position="stacked" className="ion-text-wrap">
        {props.label}
      </IonLabel>
      <IonInput
        name={props.name}
        type={props.format}
        value={props.value}
        disabled={!props.enabled}
        onIonChange={props.onChange}
        required={props.required}
        autocomplete="off"
      />
    </IonItem>
  );
});

interface PhoneNumberFieldProps extends PhoneNumberFieldDefinition {
  value: string;
  type: PhoneNumberType;
  onChange: (name: string, value: string, type: PhoneNumberType) => void;
}

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = React.memo((props) => {
  const maskRef = useRef<IMask.InputMask<any> | null>(null);
  const [value, setValue] = useState<string>(props.value);
  const [type, setType] = useState<PhoneNumberType>(props.type);

  const inputCallback = useCallback(async (input) => {
    if (!input) {
      return;
    }
    const nativeInput = await input.getInputElement();
    const mask = IMask(nativeInput, {
      mask: '+1 (000) 000-0000`',
      overwrite: true,
      autofix: true,
      lazy: true
    })
      .on('accept', (e: any) => {
        setValue(mask.value);
      })
      .on('complete', (e: any) => {
        setValue(mask.value);
      });

    maskRef.current = mask;
  }, []);

  useEffect(() => {
    props.onChange(props.name, value, type);
  }, [props, value, type]);

  return (
    <>
      <IonItem className="ion-item-pad-fix ion-item-label-only" lines="none">
        <IonLabel position="stacked" className="ion-text-wrap" color="dark">
          {props.label}
          &nbsp;<IonText color="red">*</IonText>
        </IonLabel>
      </IonItem>
      <IonItem className="ion-item-pad-fix ion-item-label-separate">
        {/* <IonIcon icon={pencil} slot="start" size="small" /> */}

        <IonInput
          name={props.name}
          type="tel"
          value={value}
          disabled={!props.enabled}
          ref={inputCallback}
          required={props.required}
          autocomplete="off"
          slot="start"
        />
        <IonSelect
          slot="end"
          className="inline-select"
          interface="popover"
          value={type}
          onIonChange={(e) => setType(e.detail.value)}
        >
          <IonSelectOption value="mobile">Mobile</IonSelectOption>
          <IonSelectOption value="landline">Landline</IonSelectOption>
        </IonSelect>
      </IonItem>
    </>
  );
});

interface TextAreaFieldProps extends TextAreaFieldDefinition {
  value: string;
  onChange: (evt: CustomEvent<TextareaChangeEventDetail>) => void;
}

const TextAreaField: React.FC<TextAreaFieldProps> = React.memo((props) => {
  return (
    <IonItem className="ion-item-pad-fix">
      {/* <IonIcon icon={pencil} slot="start" size="large" /> */}
      <IonLabel position="stacked" className="ion-text-wrap">
        {props.label}
      </IonLabel>
      <IonTextarea
        name={props.name}
        value={props.value}
        disabled={!props.enabled}
        onIonChange={props.onChange}
        required={props.required}
        rows={props.rows}
        autoGrow={props.autoGrow}
        debounce={props.debounce}
        maxlength={props.maxlength}
      />
    </IonItem>
  );
});

interface CheckBoxFieldProps extends CheckBoxFieldDefinition {
  checked?: boolean;
  onChange: (evt: CustomEvent<CheckboxChangeEventDetail>) => void;
}

const CheckBoxField: React.FC<CheckBoxFieldProps> = React.memo((props) => {
  const defaultArgs = {
    labelMode: 'top' as LabelMode,
    iconMode: 'hidden' as IconMode,
    indentLevel: 0,
    icon: undefined as any,
    enabled: true
  };

  const args = { ...defaultArgs, ...props };
  return (
    <LabeledField
      labelMode={args.labelMode}
      label={args.label}
      iconMode={args.iconMode}
      icon={args.icon}
      indentLevel={args.indentLevel}
    >
      <IonCheckbox name={props.name} disabled={!args.enabled} checked={props.checked} onIonChange={props.onChange} />
      <IonLabel>&nbsp; {props.checkboxLabel}</IonLabel>
    </LabeledField>
  );
});

interface DateFieldProps extends DateFieldDefinition {
  value: string;
  onChange: (evt: CustomEvent<DatetimeChangeEventDetail>) => void;
}

const DateField: React.FC<DateFieldProps> = React.memo((props) => {
  const defaultArgs = {
    labelMode: 'top' as LabelMode,
    iconMode: 'hidden' as IconMode,
    indentLevel: 0,
    icon: undefined as any
  };

  const args = { ...defaultArgs, ...props };
  return (
    <>
      <LabeledField
        labelMode={args.labelMode}
        label={args.label}
        iconMode={args.iconMode}
        icon={args.icon}
        indentLevel={args.indentLevel}
      >
        <IonDatetime name={args.name} value={args.value} placeholder="Select Date" onIonChange={args.onChange} />
      </LabeledField>
    </>
  );
});

interface TimeFieldProps extends TimeFieldDefinition {
  value: string;
  onChange: (evt: CustomEvent<DatetimeChangeEventDetail>) => void;
}

const TimeField: React.FC<TimeFieldProps> = React.memo((props) => {
  const defaultArgs = {
    labelMode: 'top' as LabelMode,
    iconMode: 'hidden' as IconMode,
    indentLevel: 0,
    icon: undefined as any
  };

  const args = { ...defaultArgs, ...props };
  return (
    <>
      <LabeledField
        labelMode={args.labelMode}
        label={args.label}
        iconMode={args.iconMode}
        icon={args.icon}
        indentLevel={args.indentLevel}
      >
        <IonDatetime
          name={args.name}
          value={args.value}
          displayFormat="h:mm A"
          pickerFormat="h:mm A"
          placeholder="Select Time"
          minuteValues="0,15,30,45"
          onIonChange={args.onChange}
        />
      </LabeledField>
    </>
  );
});

interface RadioGroupFieldProps extends RadioGroupFieldDefinition {
  options: RadioGroupFieldOption[];
  selectedValue: string | undefined;
  onChange: (evt: CustomEvent<RadioGroupChangeEventDetail>) => void;
}

const RadioFieldGroup: React.FC<RadioGroupFieldProps> = React.memo((props) => {
  const defaultArgs = {
    labelMode: 'top' as LabelMode,
    iconMode: 'default' as IconMode,
    indentLevel: 0,
    icon: listCircleOutline
  };

  const args = { ...defaultArgs, ...props };
  return (
    <>
      <LabeledField
        labelMode={args.labelMode}
        label={args.label}
        iconMode={args.iconMode}
        icon={args.icon}
        indentLevel={args.indentLevel}
      >
        <IonRadioGroup
          name={args.name}
          onIonChange={args.onChange}
          value={args.selectedValue}
          allowEmptySelection={args.allowEmptySelection}
        >
          {props.options.map((option) => (
            <IonItem key={option.value} lines="none">
              <IonLabel className="ion-text-wrap">{option.label}</IonLabel>
              <IonRadio slot="start" value={option.value} />
            </IonItem>
          ))}
        </IonRadioGroup>
      </LabeledField>
    </>
  );
});

interface SwitchListFieldProps extends SwitchListFieldDefinition {
  items: SwitchListFieldItem[];
  values?: SwitchListFieldItemValue[];
  onChange: (field: string, evt: CustomEvent<ToggleChangeEventDetail>) => void;
}

const SwitchListField: React.FC<SwitchListFieldProps> = React.memo((props) => {
  const defaultArgs = {
    labelMode: 'top' as LabelMode,
    iconMode: 'default' as IconMode,
    indentLevel: 0,
    icon: toggleOutline
  };

  const args = { ...defaultArgs, ...props };

  return (
    <LabeledField
      labelMode={args.labelMode}
      label={args.label}
      iconMode={args.iconMode}
      icon={args.icon}
      indentLevel={args.indentLevel}
    >
      <IonList slot="start" lines="none" className="no-padding-start no-padding-top no-padding-bottom">
        <IonRadioGroup name={args.name} key={args.name + 'group'}>
          {args.items.map((item) => {
            const itemValue = (args.values || []).find((v) => v.value === item.value);
            return (
              <IonItem key={args.name + item.value} className="no-padding-start no-padding-top no-padding-bottom">
                <IonToggle
                  key={args.name + item.value + 'toggle'}
                  className="no-padding-start"
                  value={item.value}
                  checked={itemValue?.checked || false}
                  disabled={!item.enabled}
                  onIonChange={(e) => args.onChange(args.name, e)}
                />
                <IonLabel className="ion-text-wrap">{item.label}</IonLabel>
              </IonItem>
            );
          })}
        </IonRadioGroup>
      </IonList>
    </LabeledField>
  );
});

interface SelectListFieldProps extends SelectFieldDefinition {
  items: SelectFieldItem[];
  value?: string;
  onChange: (field: string, evt: CustomEvent<SelectChangeEventDetail>) => void;
}

const SelectListField: React.FC<SelectListFieldProps> = React.memo((props) => {
  const defaultArgs = {
    labelMode: 'top' as LabelMode,
    iconMode: 'default' as IconMode,
    indentLevel: 0,
    icon: listOutline
  };

  const args = { ...defaultArgs, ...props };

  const customAlertOptions = {
    header: args.header,
    message: args.message,
    translucent: true
  };

  return (
    <LabeledField
      labelMode={args.labelMode}
      label={args.label}
      iconMode={args.iconMode}
      icon={args.icon}
      indentLevel={args.indentLevel}
    >
      <IonSelect
        hidden={false}
        name={args.name}
        placeholder={args.placeholder ?? undefined}
        selectedText={args.selectedText ?? undefined}
        okText="Done"
        cancelText="Cancel"
        multiple={false}
        value={args.value}
        interface="alert"
        interfaceOptions={customAlertOptions}
        className="full-width-select"
        onIonChange={(e) => args.onChange(props.name, e)}
      >
        {args.items.map((item) => (
          <IonSelectOption key={item.value} value={item.value} disabled={!item.enabled}>
            {item.label}
          </IonSelectOption>
        ))}
      </IonSelect>
    </LabeledField>
  );
});

interface MultiSelectListFieldProps extends MultiSelectFieldDefinition {
  items: SelectFieldItem[];
  values: string[];
  onChange: (field: string, evt: CustomEvent<SelectChangeEventDetail>) => void;
}

const MultiSelectListField: React.FC<MultiSelectListFieldProps> = React.memo((props) => {
  const defaultArgs = {
    labelMode: 'top' as LabelMode,
    iconMode: 'default' as IconMode,
    indentLevel: 0,
    icon: listOutline
  };

  const args = { ...defaultArgs, ...props };

  const customAlertOptions = {
    header: args.header,
    message: args.message,
    translucent: true
  };

  return (
    <LabeledField
      labelMode={args.labelMode}
      label={args.label}
      iconMode={args.iconMode}
      icon={args.icon}
      indentLevel={args.indentLevel}
    >
      <IonSelect
        hidden={false}
        name={args.name}
        placeholder={args.placeholder ?? undefined}
        selectedText={args.selectedText ?? undefined}
        okText="Done"
        cancelText="Cancel"
        multiple={true}
        value={args.values}
        interface="alert"
        interfaceOptions={customAlertOptions}
        className="full-width-select"
        onIonChange={(e) => args.onChange(props.name, e)}
      >
        {args.items.map((item) => (
          <IonSelectOption key={item.value} value={item.value} disabled={!item.enabled}>
            {item.label}
          </IonSelectOption>
        ))}
      </IonSelect>
    </LabeledField>
  );
});

const Indent: React.FC<{ level: number }> = ({ level }) => {
  const elements = [];
  for (let i = 0; i < level; i++) {
    elements.push(<IonIcon key={'indent' + i} slot="start" className="indent-icon" />);
  }
  return <>{elements}</>;
};

const LabeledField: React.FC<{
  labelMode: LabelMode;
  label: string;
  iconMode: IconMode;
  icon?: any;
  indentLevel?: number;
}> = ({ labelMode, label, iconMode, icon, indentLevel = 0, children }) => {
  return (
    <>
      {labelMode === 'top' && (
        <IonItem lines="none" className="label-only-item">
          <Indent level={indentLevel} />
          {iconMode === 'default' && <IonIcon icon={icon} color="medium" size="small" slot="start" />}
          {/* <IonLabel position="fixed" className="ion-text-wrap full-width-label" slot="start"> */}
          <IonText className="fixed-label-as-stacked">{label}</IonText>
          {/* </IonLabel> */}
        </IonItem>
      )}
      {children && (
        <IonItem lines="none">
          {labelMode === 'top' && <Indent level={indentLevel} />}

          {labelMode === 'left' && (
            <>
              <Indent level={indentLevel} />
              {iconMode === 'default' && <IonIcon icon={icon} slot="start" size="small" />}
              <IonLabel position="fixed" className="ion-text-wrap fixed-label-as-stacked">
                {label}
              </IonLabel>
            </>
          )}
          {labelMode === 'hidden' && (
            <>
              <Indent level={indentLevel} />
              {iconMode === 'default' && <IonIcon icon={icon} slot="start" size="small" />}
            </>
          )}
          {children}
        </IonItem>
      )}
    </>
  );
};
