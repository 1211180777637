import React, { useContext, useEffect, useState } from 'react';
import { IonContent, IonButton, IonIcon, IonCard } from '@ionic/react';
import { AppContext } from 'App';
import { useLocation, useParams } from 'react-router';
import {
  AppPage,
  AppPageAuthenticationRequirement,
  AppPageSideMenuMode,
  AppPageValidatePermissionsParams
} from 'components/AppPage';
import { FunctionsApiError } from 'common/firebase/functionsApi';
import {
  CommandType,
  CreateSubscriptionCheckoutSessionCommand,
  CreateSubscriptionPortalSessionCommand,
  GetSubscriptionEstimateQuery,
  QueryType,
  SubscriptionEstimateView
} from 'contracts/contracts.shared';
import { ErrorBox } from 'components/ErrorBox';
import { PageHeaderCenter } from 'components/PageHeaderCenter';
import { checkmarkCircle, gridOutline, lockClosed } from 'ionicons/icons';
import { createUseStyles } from 'react-jss';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';

type SubscriptionViewMode = 'create' | 'success' | 'abandoned' | 'manage';

const useSubscribeStyles = createUseStyles({
  tableLabel: {
    width: '150px',
    textAlign: 'right',
    display: 'inline-block',
    marginRight: '25px'
  },
  tableValue: {
    width: '250px',
    textAlign: 'left',
    display: 'inline-block'
  },
  '@media screen and (max-width: 480px) ': {
    tableLabel: {
      textAlign: 'center',
      marginRight: '0px'
    },
    tableValue: {
      textAlign: 'center'
    }
  },
  flair: {
    width: '100px',
    display: 'block',
    margin: '0 auto 24px auto'
  }
});

const CheckoutButton: React.FC<{ isSubmitting: boolean; onCheckout: (stripe: Stripe) => void }> = ({
  isSubmitting,
  onCheckout
}) => {
  const stripe = useStripe();

  const handleSubmit = async () => {
    onCheckout(stripe);
  };

  return (
    <IonButton
      color="primary"
      fill="solid"
      className="ion-margin-bottom"
      onClick={handleSubmit}
      disabled={isSubmitting}
      size="large"
    >
      <IonIcon icon={lockClosed} />
      &nbsp; Secure Checkout
    </IonButton>
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export interface SubscriptionPageParams {
  mode: SubscriptionViewMode;
  organizationId: string;
  tokenOrStripeResult: string;
}

export const SubscriptionPage: React.FC = () => {
  const appContext = useContext(AppContext);
  const [checkoutSessionId, setCheckoutSessionId] = useState<string>();
  const [errors, setErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [estimate, setEstimate] = useState<SubscriptionEstimateView>(null);
  const [stripe, setStripe] = useState<Stripe>(null);
  const styles = useSubscribeStyles();
  const { mode, organizationId, tokenOrStripeResult } = useParams<SubscriptionPageParams>();
  const location = useLocation();

  useEffect(() => {
    if (!checkoutSessionId) {
      return;
    }
    const errorMessages = ['There was an error redirecting to the payment provider. Please try again.'];
    stripe
      .redirectToCheckout({
        sessionId: checkoutSessionId
      })
      .then((result: any) => {
        // If `redirectToCheckout` fails due to a browser or network
        setErrors(errorMessages);
        setIsSubmitting(false);
      })
      .catch((err: any) => {
        setErrors(errorMessages);
        setIsSubmitting(false);
      });
  }, [checkoutSessionId]);

  const resetForm = () => {
    setCheckoutSessionId(null);
    setErrors([]);
    setIsSubmitting(false);
    setEstimate(null);
  };

  const onLoad = async () => {
    resetForm();
    if (!['create', 'manage'].includes(mode)) {
      return;
    }
    setIsSubmitting(true);
    try {
      if (mode == 'create') {
        const estimate = await appContext.functions.executeQuery<
          GetSubscriptionEstimateQuery,
          SubscriptionEstimateView
        >(QueryType.GetSubscriptionEstimateQuery, { organizationId, token: tokenOrStripeResult });
        setEstimate(estimate);
      } else if (mode == 'manage') {
        const response = await appContext.functions.executeCommand<
          CreateSubscriptionPortalSessionCommand,
          { url: string }
        >(CommandType.CreateSubscriptionPortalSessionCommand, { organizationId, token: tokenOrStripeResult });
        window.location.href = response.url;
      }
    } catch (err) {
      const typedErr = err as FunctionsApiError;
      setErrors(typedErr.errors);
    }
    setIsSubmitting(false);
  };

  const onCheckout = async (stripeRef: Stripe) => {
    try {
      const allowPromo = !!new URLSearchParams(location.search).get('promo');
      setStripe(stripeRef); //Ugly workaround to use stripe elements
      setIsSubmitting(true);
      const response = await appContext.functions.executeCommand<
        CreateSubscriptionCheckoutSessionCommand,
        { checkoutSessionId: string }
      >(CommandType.CreateSubscriptionCheckoutSessionCommand, {
        organizationId,
        token: tokenOrStripeResult,
        allowPromo
      });
      setCheckoutSessionId(response.checkoutSessionId);
      setIsSubmitting(false);
    } catch (err) {
      const typedErr = err as FunctionsApiError;
      setErrors(typedErr.errors);
      setIsSubmitting(false);
    }
  };

  const onValidatePermissions = (request: AppPageValidatePermissionsParams): boolean => {
    return true;
  };

  return (
    <AppPage
      appContext={appContext}
      sideMenuMode={AppPageSideMenuMode.Hide}
      authenticationRequirement={AppPageAuthenticationRequirement.Any}
      onLoad={onLoad}
      onValidatePermissions={onValidatePermissions}
      isLoading={isSubmitting}
    >
      <IonContent>
        <PageHeaderCenter
          organizationName={estimate?.organization?.name}
          buttons={
            appContext.userSettings ? (
              <>
                <IonButton slot="end" href={`${process.env.REACT_APP_BASE_URL}/dashboard`} size="small">
                  <IonIcon icon={gridOutline} slot="icon-only" />
                </IonButton>
              </>
            ) : null
          }
        />
        <div className="vertical-layout vertical-layout-compressed">
          <div style={{ margin: '0 auto', maxWidth: '600px' }}>
            <ErrorBox errors={errors} onClearErrors={() => setErrors([])} />

            {errors.length === 0 && (
              <IonCard className="page-content" style={{ minHeight: '500px' }}>
                {mode === 'create' && estimate && (
                  <>
                    <>
                      <div style={{ minHeight: '760px' }}>
                        <img
                          src="/assets/pipedrive-tiers.jpg"
                          style={{ width: '90%', paddingLeft: '5%', paddingTop: '24px' }}
                        />
                        <br /> <br />
                        <img className={styles.flair} src="/assets/flair.svg" />
                        <h5 className="text-subheading center-text">
                          <strong>
                            Monthly Estimate for
                            <br /> {estimate.organization.name}
                          </strong>
                        </h5>
                        <br />
                        <p className="center-text">
                          <span className={styles.tableLabel}>
                            <strong className="color-dark">Product</strong>
                          </span>
                          <span className={styles.tableValue}>Turbocharger for Pipedrive</span>
                        </p>
                        <p className="center-text">
                          <span className={styles.tableLabel}>
                            {' '}
                            <strong className="color-dark">Active Users</strong>
                          </span>
                          <span className={styles.tableValue}>{estimate.unitCount}</span>
                        </p>
                        <p className="center-text">
                          <span className={styles.tableLabel}>
                            {' '}
                            <strong className="color-dark">Price/User</strong>
                          </span>
                          <span className={styles.tableValue}>{estimate.unitPrice}</span>
                        </p>
                        <p className="center-text">
                          <span className={styles.tableLabel}>
                            {' '}
                            <strong className="color-dark">Total</strong>
                          </span>
                          <span className={styles.tableValue}>{estimate.total} USD</span>
                        </p>
                        <br />
                        <p className="center-text" style={{ width: '60%', margin: '0 auto' }}>
                          Month to month. No contract. Cancel anytime.
                          <br /> <br />
                          Pay only for what you use. Active users is calculated every month based on the average number
                          of active Pipedrive users linked to your account.
                        </p>
                        <br /> <br />
                        <img className={styles.flair} src="/assets/flair.svg" />
                        <div className="center-text">
                          <Elements stripe={stripePromise}>
                            <CheckoutButton isSubmitting={isSubmitting} onCheckout={onCheckout} />
                          </Elements>
                          <br /> <br />
                          You can share the link to this page with your
                          <br /> accounting department if necessary.
                        </div>
                      </div>
                    </>
                  </>
                )}

                {mode === 'abandoned' && (
                  <>
                    <p className="slogan-header center-text" style={{ paddingTop: '15%' }}>
                      <strong>Checkout Cancelled</strong>
                    </p>
                    <p className="slogan-header center-text text-xs" style={{ width: '60%' }}>
                      Payment cancelled. You may try again by pressing the button below.
                    </p>
                    <div className="center-content center-text">
                      <IonButton routerLink={`/subscription/create/${organizationId}/${tokenOrStripeResult}`}>
                        Start Over
                      </IonButton>
                    </div>
                  </>
                )}
                {mode === 'success' && (
                  <>
                    <p className="slogan-header center-text" style={{ paddingTop: '15%' }}>
                      <IonIcon size="medium" icon={checkmarkCircle}></IonIcon>
                      <strong> Checkout Complete</strong>
                    </p>
                    <br />
                    <p className="slogan-header center-text text-xs" style={{ width: '60%' }}>
                      Thank you for using Turbocharger&trade;.You will receive an email confirmation for your
                      subscription shortly.
                      <br /> <br />
                      You may close this window.
                    </p>
                  </>
                )}

                {mode === 'manage' && !errors?.length && (
                  <>
                    <p className="slogan-header center-text" style={{ paddingTop: '10%' }}></p>
                    <p className="slogan-header center-text text-xs">Connecting to billing portal...</p>
                  </>
                )}
              </IonCard>
            )}
          </div>
        </div>
      </IonContent>
    </AppPage>
  );
};
