import React, { useContext, useState } from 'react';
import { IonContent, IonButton, IonIcon } from '@ionic/react';
import { AppContext } from 'App';
import {
  AppPage,
  AppPageAuthenticationRequirement,
  AppPageSideMenuMode,
  AppPageValidatePermissionsParams
} from 'components/AppPage';
import { ErrorBox } from 'components/ErrorBox';
import { FunctionsApiError } from 'common/firebase/functionsApi';
import { CommandType, UnsubscribeMailCommand } from 'contracts/contracts.shared';
import { helpCircleOutline } from 'ionicons/icons';
import { PageHeaderCenter } from 'components/PageHeaderCenter';
import { useLocation, useParams } from 'react-router';

interface MailUnsubscribePageParams {
  mailId: string;
}

export const MailUnsubscribePage: React.FC = () => {
  const appContext = useContext(AppContext);
  const { mailId } = useParams<MailUnsubscribePageParams>();
  const [errors, setErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>(null);
  const location = useLocation();

  const resetForm = () => {
    setErrors([]);
    setIsSubmitting(false);
  };

  const onLoad = async () => {
    resetForm();
    const e = new URLSearchParams(location.search).get('e');
    if (!e) {
      setErrors(['Invalid email address']);
    } else {
      setEmailAddress(e);
    }
  };

  const onSubmit = async (e: React.MouseEvent<HTMLIonButtonElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await appContext.functions.executeCommand<UnsubscribeMailCommand, boolean>(CommandType.UnsubscribeMailCommand, {
        mailId,
        emailAddress
      });

      setIsSubmitting(false);
      setIsCompleted(true);
    } catch (err) {
      const typedErr = err as FunctionsApiError;
      setErrors(typedErr.errors);
      setIsSubmitting(false);
    }
  };

  const onValidatePermissions = (request: AppPageValidatePermissionsParams): boolean => {
    return true;
  };

  return (
    <AppPage
      appContext={appContext}
      sideMenuMode={AppPageSideMenuMode.Hide}
      authenticationRequirement={AppPageAuthenticationRequirement.Any}
      onLoad={onLoad}
      onValidatePermissions={onValidatePermissions}
      isLoading={isSubmitting}
    >
      <IonContent>
        <>
          <PageHeaderCenter
            organizationName={null}
            buttons={
              appContext.userSettings ? (
                <>
                  <IonButton slot="end" href={process.env.REACT_APP_MARKETING_BASE_URL} size="small">
                    <IonIcon icon={helpCircleOutline} slot="icon-only" />
                  </IonButton>
                </>
              ) : null
            }
          />

          <div className="vertical-layout vertical-layout-compressed">
            <div style={{ margin: '0 auto', background: 'transparent' }}>
              <div style={{ width: '80%', margin: '0 auto', minHeight: '300px' }}>
                <h2 className="center-text">Unsubscribe?</h2>
                <ErrorBox errors={errors} onClearErrors={() => setErrors([])} />

                {isCompleted && emailAddress && (
                  <p className="text-light-weight text-md center-text">
                    Success: {emailAddress} has been unsubscribed.
                  </p>
                )}
                {!isCompleted && mailId && emailAddress && (
                  <>
                    <p className="text-light-weight text-md center-text">
                      Do you want unsubscribe {emailAddress} from all future emails? <br />
                      <br />
                      <br />
                      <IonButton
                        color="primary"
                        fill="solid"
                        className="ion-margin-bottom"
                        size="large"
                        onClick={onSubmit}
                        disabled={isSubmitting}
                      >
                        Unsubscribe &gt;
                      </IonButton>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      </IonContent>
    </AppPage>
  );
};
