import React, { useContext, useState } from 'react';
import { IonContent, IonButton, IonIcon } from '@ionic/react';
import { AppContext } from 'App';
import {
  AppPage,
  AppPageAuthenticationRequirement,
  AppPageSideMenuMode,
  AppPageValidatePermissionsParams
} from 'components/AppPage';

import { gridOutline } from 'ionicons/icons';
import { PageHeaderCenter } from 'components/PageHeaderCenter';
import { useLocation } from 'react-router';

export enum StartOverPageMode {
  AccessDenied,
  NotFound
}

export const StartOverPage: React.FC<{ mode: StartOverPageMode }> = ({ mode }) => {
  const appContext = useContext(AppContext);
  const [page, setPage] = useState<string>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const location = useLocation();
  const onLoad = async () => {
    setIsSubmitting(false);
    const r = new URLSearchParams(location.search).get('r');
    if (r) {
      setPage(r);
    }
  };

  const onValidatePermissions = (request: AppPageValidatePermissionsParams): boolean => {
    return true;
  };

  return (
    <AppPage
      appContext={appContext}
      sideMenuMode={AppPageSideMenuMode.Hide}
      authenticationRequirement={AppPageAuthenticationRequirement.Any}
      onLoad={onLoad}
      onValidatePermissions={onValidatePermissions}
      isLoading={isSubmitting}
    >
      <IonContent>
        <>
          <PageHeaderCenter
            organizationName={appContext.userSettings?.organization?.name}
            buttons={
              appContext.userSettings ? (
                <>
                  <IonButton slot="end" href={`${process.env.REACT_APP_BASE_URL}/dashboard`} size="small">
                    <IonIcon icon={gridOutline} slot="icon-only" />
                  </IonButton>
                </>
              ) : null
            }
          />

          <div className="vertical-layout">
            <div className="center-text">
              {mode === StartOverPageMode.AccessDenied && (
                <>
                  <h3>Access Denied</h3>
                  {page && <p>You do not have access to {page}.</p>}
                  {!page && <p>You do not have access to this resource.</p>}
                </>
              )}
              {mode === StartOverPageMode.NotFound && (
                <>
                  <h3>Not Found</h3>

                  <p>
                    Please double check the address
                    <br /> and try again or start over.
                  </p>
                </>
              )}
              <IonButton fill="outline" color="primary" routerLink={appContext.userSettings ? '/dashboard' : '/signin'}>
                Start Over
              </IonButton>
            </div>
          </div>
        </>
      </IonContent>
    </AppPage>
  );
};
