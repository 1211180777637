import { IonCard, IonToolbar, IonButton, IonIcon, IonCardContent, IonText, IonItem, IonList } from '@ionic/react';
import { closeCircleOutline, alertCircleOutline } from 'ionicons/icons';
import React from 'react';

export const ErrorBox: React.FC<{ errors: string[]; onClearErrors: () => void; clearDisabled?: boolean }> = ({
  errors,
  onClearErrors,
  clearDisabled
}) => {
  return errors?.length ? (
    <IonCard>
      <IonCardContent>
        <IonToolbar color="clear" className="ion min-height-none">
          <IonIcon slot="start" icon={alertCircleOutline} size="medium" />
          <IonText slot="start" color="danger">
            <strong>Error</strong>
          </IonText>
          <IonButton
            slot="end"
            fill="clear"
            color="medium"
            size="small"
            onClick={() => onClearErrors()}
            disabled={clearDisabled === true}
          >
            <IonIcon icon={closeCircleOutline} />
          </IonButton>
        </IonToolbar>
        <IonList>
          {errors.map((e: string) => (
            <IonItem key={e} lines="none">
              <IonText slot="start" className="error-bullet no-padding-start ">
                *
              </IonText>
              <IonText className="no-padding-start" style={{ marginLeft: '15px' }}>
                <span dangerouslySetInnerHTML={{ __html: e }}></span>
              </IonText>
            </IonItem>
          ))}
        </IonList>
      </IonCardContent>
    </IonCard>
  ) : (
    <></>
  );
};
