import React, { useEffect, useState, createContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane, IonLoading } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { SideMenu } from './components/SideMenu';
import { AuthApi } from 'common/firebase/authApi';
import { AnalyticsApi } from 'common/firebase/analyticsApi';
import { FunctionsApi } from 'common/firebase/functionsApi';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/firestore';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/app.scss';

import { PipedriveConnectPage } from 'pages/PipedriveConnect';
import { StorageApi } from 'common/firebase/storageApi';
import { BrowserStore } from 'common/browserStore';
import { PipedriveSetupPage } from 'pages/PipedriveSetup';
import { ContactActivityPage } from 'pages/ContactActivity';
import { SubscriptionPage } from 'pages/Subscription';
import { AccountSettingsPage } from 'pages/AccountSettings';
import { TrackingSettingsPage } from 'pages/TrackingSettings';
import { GetUserSettingsQuery, QueryType, UserSettingsView } from 'contracts/contracts.shared';
import { StartOverPage, StartOverPageMode } from 'pages/StartOver';
import { TrackingSettingsUpdatePage } from 'pages/TrackingSettingsUpdate';
import { ContactsPage } from 'pages/Contacts';
import { DashboardPage } from 'pages/Dashboard';
import { SignInPage } from 'pages/SignIn';
import { SignOutPage } from 'pages/SignOut';
import { MailUnsubscribePage } from 'pages/MailUnsubscribe';
import { SetupTrackingDomainPage } from 'pages/SetupTrackingDomain';

export interface IAppContext {
  user?: firebase.User;
  userSettings?: UserSettingsView;
  auth: AuthApi;
  analytics: AnalyticsApi;
  functions: FunctionsApi;
  storage: StorageApi;
  browserStore: BrowserStore;
  firestore: firebase.firestore.Firestore;
  isLoaded: boolean;
  isMenuHidden: boolean;
  setMenuHidden: (hidden: boolean) => void;
  setUserSettings: (userSettings: UserSettingsView) => void;
}

const appContextDefault: IAppContext = {
  user: undefined,
  userSettings: undefined,
  auth: undefined,
  analytics: undefined,
  functions: undefined,
  storage: undefined,
  browserStore: undefined,
  isLoaded: false,
  isMenuHidden: true,
  firestore: undefined,
  setMenuHidden: undefined,
  setUserSettings: undefined
};

export const AppContext = createContext<IAppContext>(appContextDefault);

export enum Area {
  Dashboard,
  Contacts,
  Users,
  Websites,
  Tracking,
  Account
}

const App: React.FC = () => {
  const [appContext, setAppContext] = useState<IAppContext>({
    ...appContextDefault
  });

  const setMenuHidden = (hidden: boolean) => {
    setAppContext((a) => {
      if (a.isMenuHidden !== hidden) {
        return { ...a, isMenuHidden: hidden };
      } else {
        return a;
      }
    });
  };

  const setUserSettings = (userSettings: UserSettingsView) => {
    setAppContext((a) => {
      return { ...a, userSettings };
    });
  };

  useEffect(
    () => {
      const firebaseApp = firebase.initializeApp({
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_APP_ID,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID
      });

      const firebaseAuth = firebaseApp.auth();
      const firebaseFunctions = firebaseApp.functions();
      const firebaseAnalytics = firebaseApp.analytics();
      const firebaseStorage = firebaseApp.storage();
      const firestore = firebaseApp.firestore();

      const authApi = new AuthApi(firebaseAuth);
      const analyticsApi = new AnalyticsApi(firebaseAnalytics);
      const functionsApi = new FunctionsApi(firebaseFunctions);
      const storageApi = new StorageApi(firebaseStorage);
      const browserStore = new BrowserStore();

      firebaseAuth.onAuthStateChanged(async (fUser) => {
        let userSettings: UserSettingsView = null;
        if (fUser) {
          analyticsApi.setAccountId(fUser.uid);
          try {
            userSettings = await functionsApi.executeQuery<GetUserSettingsQuery, UserSettingsView>(
              QueryType.GetUserSettingsQuery,
              {}
            );
          } catch (err) {
            await authApi.logout();
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }
        }

        setAppContext((a) => {
          return {
            ...a,
            user: fUser ? fUser : undefined,
            auth: authApi,
            analytics: analyticsApi,
            functions: functionsApi,
            storage: storageApi,
            isLoaded: true,
            userSettings,
            browserStore,
            firestore,
            setUserSettings
          };
        });
      });
    },
    [] /* Ensure this code will only run once */
  );

  return (
    <IonApp>
      <AppContext.Provider value={{ ...appContext, setMenuHidden }}>
        <IonLoading isOpen={!appContext.isLoaded} spinner="dots" cssClass="custom-loading" />
        {appContext.isLoaded && (
          <IonReactRouter>
            <IonSplitPane contentId="main">
              <SideMenu />
              <IonRouterOutlet id="main">
                <Route path="/signin" exact={true}>
                  <SignInPage />
                </Route>
                <Route path="/signout" exact={true}>
                  <SignOutPage />
                </Route>
                <Route path="/setup/tracking/:organizationId/:trackingDomainToken" exact={true}>
                  <SetupTrackingDomainPage />
                </Route>
                <Route path="/activity/:organizationId/contacts/:contactId/:contactToken" exact={true}>
                  <ContactActivityPage />
                </Route>
                <Route path="/subscription/:mode/:organizationId/:tokenOrStripeResult?" exact={true}>
                  <SubscriptionPage />
                </Route>
                <Route path="/dashboard" exact={true}>
                  <DashboardPage />
                </Route>
                <Route path="/contacts" exact={true}>
                  <ContactsPage />
                </Route>
                <Route path="/settings/account" exact={true}>
                  <AccountSettingsPage />
                </Route>
                <Route path="/settings/tracking" exact={true}>
                  <TrackingSettingsPage />
                </Route>
                <Route path="/settings/tracking/edit" exact={true}>
                  <TrackingSettingsUpdatePage />
                </Route>
                <Route path="/mails/:mailId/unsubscribe" exact={true}>
                  <MailUnsubscribePage />
                </Route>
                {/* Important: /pipedrive route prefix is mapped to function so cannot be used here*/}
                <Route path="/connect/pipedrive" exact={true}>
                  <PipedriveConnectPage />
                </Route>
                <Route path="/setup/pipedrive" exact={true}>
                  <PipedriveSetupPage />
                </Route>
                <Route path="/access-denied" exact={true}>
                  <StartOverPage mode={StartOverPageMode.AccessDenied} />
                </Route>
                <Route path="/" exact={true}>
                  <Redirect to="/signin" />
                </Route>
                <Route>
                  <StartOverPage mode={StartOverPageMode.NotFound} />
                </Route>
              </IonRouterOutlet>
            </IonSplitPane>
          </IonReactRouter>
        )}
      </AppContext.Provider>
    </IonApp>
  );
};

export default App;
