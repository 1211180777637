import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonItemDivider,
  IonBadge
} from '@ionic/react';
import React, { useContext } from 'react';
import { AppContext, Area } from 'App';
import { settingsOutline } from 'ionicons/icons';
import { getAreaMenuItem } from '../common/areas';
import Avatar from 'react-avatar';
import { UserRole } from 'contracts/contracts.shared';
import { AnnouncementBar } from './AnnouncementBar';

interface AppPage {
  url: string;
  icon: string;
  title: string;
}

const getPages = (): AppPage[] => {
  return [getAreaMenuItem(Area.Dashboard), getAreaMenuItem(Area.Contacts)];
};

const getAdminPages = (): AppPage[] => {
  return [getAreaMenuItem(Area.Tracking), getAreaMenuItem(Area.Account)];
};

export const SideMenu: React.FunctionComponent = () => {
  const appContext = useContext(AppContext);
  const pages = getPages();
  const adminPages = getAdminPages();

  return (
    <IonMenu className="main-menu" menuId="main" contentId="main" type="reveal" hidden={appContext.isMenuHidden}>
      {appContext.userSettings?.organization && (
        <>
          <AnnouncementBar
            sideMenuMode={true}
            subscription={appContext.userSettings.organization.subscription}
            organizationId={appContext.userSettings.organization.id}
          />

          <IonHeader className="main-header text-center">
            <img alt="Logo" className="turbocharger-logo" src={'/assets/logo-turbocharger-w.png'} />
          </IonHeader>
          <IonContent>
            <IonList>
              <IonItem lines="none">
                <IonLabel className="center-text">
                  <Avatar
                    name={appContext.userSettings?.organization?.name}
                    size="16"
                    round={false}
                    maxInitials={1}
                    className="border-dark"
                  />
                  &nbsp; {appContext.userSettings?.organization?.name}
                  <br />
                  <IonBadge color="light" className="text-xs text-light-weight" style={{ marginTop: '10px' }}>
                    Pipedrive
                  </IonBadge>
                </IonLabel>
              </IonItem>
              <IonItemDivider className="main-menu-divider" />
              <IonMenuToggle autoHide={false}>
                {pages.map((page) => {
                  return (
                    <IonItem routerLink={page.url} routerDirection="none" key={page.url}>
                      <IonIcon slot="start" icon={page.icon} />
                      <IonLabel>{page.title}</IonLabel>
                    </IonItem>
                  );
                })}
              </IonMenuToggle>
              {appContext.userSettings?.role === UserRole.Admin && adminPages?.length && (
                <>
                  <IonItem routerDirection="none">
                    <IonIcon slot="start" icon={settingsOutline} />
                    <IonLabel>Administration</IonLabel>
                    {/* <IonIcon slot="end" icon={caretDownOutline} /> */}
                  </IonItem>
                  <IonMenuToggle key="admin" autoHide={false}>
                    <IonList>
                      {adminPages.map((page) => {
                        return (
                          // <IonMenuToggle key={page.url} autoHide={false}>
                          <IonItem routerLink={page.url} routerDirection="none" key={page.url}>
                            <IonIcon slot="start" icon={undefined} />
                            <IonIcon slot="start" icon={page.icon} />
                            <IonLabel>{page.title}</IonLabel>
                          </IonItem>
                          // </IonMenuToggle>
                        );
                      })}
                    </IonList>
                  </IonMenuToggle>
                </>
              )}
            </IonList>
          </IonContent>
        </>
      )}
    </IonMenu>
  );
};
// Note: 4/2020: Not sure if withRouter is needed for native apps. Works without on web.
// export default withRouter(SideMenu);
