import { IonCard, IonContent, IonRow, IonCol, IonText, IonIcon } from '@ionic/react';
import React, { useContext, useState } from 'react';
import { AppContext } from 'App';
import { AppPage, AppPageAuthenticationRequirement, AppPageSideMenuMode } from 'components/AppPage';
import { GetDashboardQuery, DashboardView, QueryType, TaskStatus } from 'contracts/contracts.shared';
import { PageHeader } from 'components/PageHeader';
import { FunctionsApiError } from 'common/firebase/functionsApi';
import { isoDateToTimeAgo } from 'common/helpers';
import { DateTime } from 'luxon';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { ErrorBox } from 'components/ErrorBox';
import { syncOutline } from 'ionicons/icons';

export const DashboardPage: React.FC = () => {
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [view, setView] = useState<DashboardView>();
  const [webViewChartData, setWebViewChartData] = useState<any>();
  const [alertChartData, setAlertChartData] = useState<any>();

  const onLoad = async () => {
    try {
      setIsLoading(true);
      const data = await appContext.functions.executeQuery<GetDashboardQuery, DashboardView>(
        QueryType.GetDashboardQuery,
        {}
      );
      setView(data);
      setAlertChartData(
        data.dailyAlerts.records.map((m) => {
          return {
            day: DateTime.fromISO(m.day)
              .toLocaleString(DateTime.DATE_MED as any)
              .split(',')?.[0],
            count: m.count
          };
        })
      );

      setWebViewChartData(
        data.dailyWebViews.records.map((m) => {
          return {
            day: DateTime.fromISO(m.day)
              .toLocaleString(DateTime.DATE_MED as any)
              .split(',')?.[0],
            count: m.count
          };
        })
      );

      setIsLoading(false);
    } catch (err) {
      setErrors((err as FunctionsApiError)?.errors || []);
      setIsLoading(false);
    }
  };

  const onValidatePermissions = (): boolean => {
    return true;
  };

  return (
    <AppPage
      appContext={appContext}
      sideMenuMode={AppPageSideMenuMode.Show}
      authenticationRequirement={AppPageAuthenticationRequirement.AuthenticatedUser}
      onValidatePermissions={onValidatePermissions}
      onLoad={onLoad}
      isLoading={isLoading}
    >
      <PageHeader title="Dashboard" />
      <IonContent>
        <IonCard className="page-content">
          {view && (
            <div className="panel panel-padded">
              <ErrorBox errors={errors} onClearErrors={() => setErrors([])} />
              <div className="panel">
                <h5 className="text-subheading">Account Overview</h5>
                <div className="stat-grid stat-grid-extra ">
                  <IonRow className="ion-text-center ion-align-items-end">
                    <IonCol>
                      {view.tasks.contactImport !== TaskStatus.Completed && (
                        <div className="stat-number">
                          <IonIcon icon={syncOutline} /> Syncing
                        </div>
                      )}

                      {view.tasks.contactImport === TaskStatus.Completed && (
                        <div className="stat-number">{view.totals.activeContacts}</div>
                      )}

                      <IonText color="medium">
                        <div className="stat-label">Contacts</div>
                      </IonText>
                    </IonCol>
                    <IonCol>
                      {view.tasks.userImport !== TaskStatus.Completed && (
                        <div className="stat-number">
                          <IonIcon icon={syncOutline} /> Syncing
                        </div>
                      )}

                      {view.tasks.userImport === TaskStatus.Completed && (
                        <div className="stat-number">{view.totals.activeUsers}</div>
                      )}
                      <IonText color="medium">
                        <div className="stat-label">Users</div>
                      </IonText>
                    </IonCol>
                    <IonCol>
                      <div className="stat-number">{view.totals.activeTrackingSources}</div>
                      <IonText color="medium">
                        <div className="stat-label">Tracking Sources</div>
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-text-center ion-align-items-end border-top">
                    <IonCol>
                      <div className="stat-number">Pipedrive</div>
                      <IonText color="medium">
                        <div className="stat-label">Sales CRM</div>
                      </IonText>
                    </IonCol>
                    <IonCol>
                      <div className="stat-number">{isoDateToTimeAgo(view.totals.lastAlertAt, '-')}</div>
                      <IonText color="medium">
                        <div className="stat-label">Last Activity Alert</div>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </div>
              </div>

              <div className="panel">
                <h5 className="text-subheading">Contact Activity Alerts</h5>
                <div className="stat-grid stat-grid-extra">
                  <IonRow className="ion-text-center ion-align-items-end">
                    <IonCol>
                      <div className="stat-number">{view.totals.alertsToday}</div>
                      <IonText color="medium">
                        <div className="stat-label">Alerts Today</div>
                      </IonText>
                    </IonCol>

                    <IonCol>
                      <div className="stat-number">{view.totals.alerts}</div>
                      <IonText color="medium">
                        <div className="text-xs">Alerts All Time</div>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </div>
                <br />
                <div className="text-xs">Contact Activity Alerts: Last 7 Days</div>
                <br />
                {alertChartData && (
                  <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart
                        data={alertChartData}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="count" name="Total Activity Alerts" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                )}
              </div>

              <div className="panel">
                <h5 className="text-subheading">Contact Website Visits</h5>
                <div className="stat-grid stat-grid-extra">
                  <IonRow className="ion-text-center ion-align-items-end">
                    <IonCol>
                      <div className="stat-number">{view.totals.webViewsToday}</div>
                      <IonText color="medium">
                        <div className="stat-label">Pageviews Today</div>
                      </IonText>
                    </IonCol>

                    <IonCol>
                      <div className="stat-number">{view.totals.webViews}</div>
                      <IonText color="medium">
                        <div className="text-xs">Pageviews All Time</div>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </div>
                <br />
                <div className="text-xs">Contact Pageviews: Last 30 Days</div>
                <br />

                {webViewChartData && (
                  <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={webViewChartData}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="count"
                          name="Total Pageviews"
                          stroke="#8884d8"
                          activeDot={{ r: 0 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                )}
              </div>
            </div>
          )}
        </IonCard>
      </IonContent>
    </AppPage>
  );
};
