import { IonBadge, IonCard, IonContent, IonRouterLink } from '@ionic/react';
import React, { useContext, useState } from 'react';
import { AppContext } from 'App';
import {
  AppPage,
  AppPageAuthenticationRequirement,
  AppPageSideMenuMode,
  AppPageValidatePermissionsParams
} from 'components/AppPage';
import { PageHeader } from 'components/PageHeader';
import {
  CrmType,
  GetOrganizationAccountDetailsQuery,
  OrganizationAccountDetailsView,
  QueryType,
  SubscriptionStatus,
  UserRole
} from 'contracts/contracts.shared';
import { FunctionsApiError } from 'common/firebase/functionsApi';
import { DateTime } from 'luxon';
import { ErrorBox } from 'components/ErrorBox';

export const AccountSettingsPage: React.FC = () => {
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [view, setView] = useState<OrganizationAccountDetailsView>();

  const onLoad = async () => {
    try {
      setIsLoading(true);
      const data = await appContext.functions.executeQuery<
        GetOrganizationAccountDetailsQuery,
        OrganizationAccountDetailsView
      >(QueryType.GetOrganizationAccountDetailsQuery, {});
      setView(data);

      setIsLoading(false);
    } catch (err) {
      setErrors((err as FunctionsApiError)?.errors || []);
      setIsLoading(false);
    }
  };

  const onValidatePermissions = (request: AppPageValidatePermissionsParams): boolean => {
    return request.context.userSettings.role === UserRole.Admin;
  };

  return (
    <AppPage
      appContext={appContext}
      sideMenuMode={AppPageSideMenuMode.Show}
      authenticationRequirement={AppPageAuthenticationRequirement.AuthenticatedUser}
      onValidatePermissions={onValidatePermissions}
      onLoad={onLoad}
      isLoading={isLoading}
    >
      <PageHeader title="Account" />
      <IonContent>
        <IonCard className="page-content">
          {view && (
            <>
              <div className="panel panel-padded">
                <ErrorBox errors={errors} onClearErrors={() => setErrors([])} />
                <div className="panel">
                  <h5 className="text-subheading-underline ">Company Information</h5>
                  <h6 className="data-label">Name</h6>
                  <div>{view.organization.name}</div>
                  <h6 className="data-label">Sales CRM</h6>
                  <div>{view.organization.crm === CrmType.Pipedrive ? 'Pipedrive' : 'None'}</div>
                  <h6 className="data-label">Timezone</h6>
                  <div>{view.organization.timezone}</div>
                </div>
                <div className="panel">
                  <h5 className="text-subheading-underline ">Billing Information</h5>
                  <h6 className="data-label">Plan</h6>
                  <div>
                    {view.subscription.status === SubscriptionStatus.Trial
                      ? `Free Trial - Expires ${DateTime.fromISO(view.subscription.trialEndsAt).toLocaleString(
                          DateTime.DATETIME_FULL as any
                        )}`
                      : view.subscription.status === SubscriptionStatus.Active
                      ? 'Paid Plan'
                      : 'Cancelled'}
                  </div>
                  <h6 className="data-label">Max Active Users</h6>
                  <div>
                    {view.subscription.status === SubscriptionStatus.Trial
                      ? 'Unlimited during trial'
                      : view.subscription.currentPeriodLicenseQuantity}
                  </div>
                  <h6 className="data-label">Max Active Contacts</h6>
                  <div>Unlimited</div>

                  <h6 className="data-label">Billing Options</h6>

                  {view.subscription.status === SubscriptionStatus.Trial && (
                    <div>
                      <div>
                        <IonBadge color="warning">Upgrade Today!</IonBadge>

                        <span style={{ verticalAlign: 'top' }}>
                          &nbsp; Plans are based on the number of Pipedrive users.
                        </span>
                      </div>
                      <br />
                      <a
                        href={`${process.env.REACT_APP_BASE_URL}/subscription/create/${view.organization.id}/${view.subscription.createToken}`}
                      >
                        <u>See pricing and upgrade options</u>
                      </a>
                    </div>
                  )}

                  {view.subscription.status === SubscriptionStatus.Active && (
                    <div>
                      <IonRouterLink
                        routerLink={`/subscription/manage/${view.organization.id}/${view.subscription.manageToken}`}
                      >
                        <u>Update payment method on file</u>
                      </IonRouterLink>
                      <br /> <br />
                      <IonRouterLink
                        routerLink={`/subscription/manage/${view.organization.id}/${view.subscription.manageToken}`}
                      >
                        <u>View billing details and history</u>
                      </IonRouterLink>
                    </div>
                  )}
                  <br />
                  <div className="panel">
                    <h5 className="text-subheading-underline ">Account Information</h5>
                    <h6 className="data-label">Total Activity Alerts</h6>
                    <div>{view.counters.totalAlerts}</div>
                    <h6 className="data-label">Total Tracked Pageviews</h6>
                    <div>{view.counters.totalWebViews}</div>
                    <h6 className="data-label">Total Active Users</h6>
                    <div>{view.counters.totalActiveUsers}</div>
                    <h6 className="data-label">Total Active Contacts</h6>
                    <div>{view.counters.totalActiveContacts}</div>
                    <h6 className="data-label">Cancellation Options</h6>
                    <div>
                      <div>
                        Feedback or Questions?
                        <br /> Email turbo@turbochargerhq.com. We would love to find out more about your experience.
                        <br /> <br />
                        {!appContext.userSettings?.isOwner && (
                          <>
                            Only the Pipedrive Admin user that originally installed Turbocharger can perform this
                            action. If this person is no longer with your organization, deactivating them will cancel
                            the this account automatically.
                          </>
                        )}
                        {appContext.userSettings?.isOwner && (
                          <>
                            You are the account owner. You may cancel your account using the link below. You will be
                            redirected to remove our application from Pipedrive. If you are on a paid plan, your
                            subscription and access will be terminated immediately and you will not be charged again.{' '}
                            <br />
                            <br />
                            <a
                              href={`https://${view.organization.pipedrive.subdomain}.pipedrive.com/settings/marketplace`}
                            >
                              <u>Cancel my account</u>
                            </a>
                          </>
                        )}
                        <br />
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </IonCard>
      </IonContent>
    </AppPage>
  );
};
