import { useState, useCallback } from 'react';
import React from 'react';
import { IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent } from '@ionic/react';
import { Form, FormField, FormSubmitResult } from './Form';
import { AreaSettings } from '../common/areas';
import {
  AppPage,
  AppPageSideMenuMode,
  AppPageAuthenticationRequirement,
  AppPageValidatePermissionsParams
} from './AppPage';
import { IAppContext } from 'App';

export interface FormPageActionResult {
  definition: FormField[];
  definitionData: any;
  values: any;
}

export interface FormPageProps {
  appContext: IAppContext;
  areaSettings: AreaSettings;
  title: string;
  onEnter: () => Promise<FormPageActionResult | void>;
  onSubmit: (form: any) => Promise<FormSubmitResult>;
  onFieldChanged?: (
    field: FormField,
    value: any,
    formValues: any,
    formDefinitionData: any
  ) => Promise<FormPageActionResult | void>;
  onValidatePermissions: (request: AppPageValidatePermissionsParams) => boolean;
}

export const FormPage: React.FC<FormPageProps> = ({
  appContext,
  title,
  areaSettings,
  onSubmit,
  onEnter,
  onFieldChanged,
  onValidatePermissions
}) => {
  const [formDefinition, setFormDefinition] = useState<FormField[]>([]);
  const [formDefinitionData, setFormDefinitionData] = useState<any>({});
  const [formValues, setFormValues] = useState<any>();
  // const [isExiting, setIsExiting] = useState<boolean>(false);

  const onLoad = useCallback(async (): Promise<void> => {
    // setIsExiting(false);
    setFormValues(undefined);
    setFormDefinition([]);
    async function runOnEnter() {
      const result = await onEnter();
      if (result) {
        setFormDefinitionData(result.definitionData);
        setFormDefinition(result.definition);
        setFormValues(result.values);
      }
    }
    runOnEnter();
  }, []);

  const onFormSubmit = useCallback(
    async (form: any): Promise<FormSubmitResult> => {
      return await onSubmit(form);
    },
    [onSubmit]
  );

  const onFormFieldChanged = useCallback(
    async (field: FormField, fieldValue: any, values: any): Promise<void> => {
      if (!onFieldChanged) return;
      const result = await onFieldChanged(field, fieldValue, values, formDefinitionData);
      if (result) {
        setFormDefinitionData(result.definitionData);
        setFormDefinition(result.definition);
        setFormValues(result.values);
      }
    },
    [onFieldChanged, formDefinitionData]
  );

  return (
    <AppPage
      appContext={appContext}
      sideMenuMode={AppPageSideMenuMode.Show}
      authenticationRequirement={AppPageAuthenticationRequirement.AuthenticatedUser}
      onValidatePermissions={onValidatePermissions}
      onLoad={onLoad}
    >
      <IonHeader className="main-header ">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={areaSettings.url} />
          </IonButtons>
          <IonTitle>{areaSettings.title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent id="pageContent">
        <div className="form-content">
          <Form
            parentContentId="pageContent"
            title={title}
            icon={areaSettings.icon}
            fields={formDefinition}
            values={formValues}
            onSubmit={onFormSubmit}
            onCancelRouterLink={areaSettings.url}
            onFieldChanged={onFormFieldChanged ?? undefined}
          />
        </div>
      </IonContent>
    </AppPage>
  );
};
