import { DateTime } from 'luxon';

export const ionContent = (elementName: string) => {
  const elm: any = document.getElementById(elementName);
  return {
    scrollToTop: () => elm?.scrollToTop()
  };
};

const units: Intl.RelativeTimeFormatUnit[] = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];
export const isoDateToTimeAgo = (dateTimeIso: string, defaultValue: string) => {
  const dateTime = DateTime.fromISO(dateTimeIso);
  if (!dateTime || !dateTime.isValid) {
    return defaultValue;
  }

  if (dateTime.toUTC().toSeconds() === 0) {
    return defaultValue; // Epoch treated same as null.
  }

  const diff = dateTime.diffNow().shiftTo(...units);

  const unit = units.find((u) => diff.get(u) !== 0) || 'second';

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto'
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

export const isoDateToDay = (dateTimeIso: string, defaultValue: string) => {
  const dateTime = DateTime.fromISO(dateTimeIso);
  if (!dateTime || !dateTime.isValid) {
    return defaultValue;
  }
  if (dateTime.toUTC().toSeconds() === 0) {
    return defaultValue; //Epoch treated same as null.
  }

  return dateTime.toLocaleString(DateTime.DATE_MED);
};

export const isoDateToWeekDay = (dateTimeIso: string, defaultValue: string) => {
  const dateTime = DateTime.fromISO(dateTimeIso);
  if (!dateTime || !dateTime.isValid) {
    return defaultValue;
  }
  if (dateTime.toUTC().toSeconds() === 0) {
    return defaultValue; //Epoch treated same as null.
  }

  return dateTime.toLocaleString(DateTime.DATE_HUGE);
};

export const isoDateToTime = (dateTimeIso: string, defaultValue: string) => {
  const dateTime = DateTime.fromISO(dateTimeIso);
  if (!dateTime || !dateTime.isValid) {
    return defaultValue;
  }
  const time = dateTime.toLocaleString(DateTime.TIME_WITH_SECONDS);
  return time.length === 10 ? '&nbsp;&nbsp;' + time : time; // Pad without leading 0
};
export const isNullOrUndefinedOrEmpty = (obj: any): boolean => obj === null || obj === undefined || obj === '';
