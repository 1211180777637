import firebase from 'firebase/app';
import 'firebase/storage';

export class StorageApi {
  // Notes:
  // 1) Must wrap calls in promises to to fb library
  // See https://github.com/firebase/firebase-js-sdk/issues/1881
  public storage: firebase.storage.Storage;
  constructor(fbAuth: firebase.storage.Storage) {
    this.storage = fbAuth;
  }
}
