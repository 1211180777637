import React from 'react';
import { IonHeader, IonToolbar, IonChip } from '@ionic/react';
import { IsoDateTimeString, SubscriptionStatus } from '../contracts/contracts.shared';
import { DateTime } from 'luxon';

export const AnnouncementBar: React.FC<{
  organizationId: string;
  subscription: { status: SubscriptionStatus; createToken: string; trialEndsAt?: IsoDateTimeString };
  sideMenuMode?: boolean;
}> = ({ organizationId, subscription, sideMenuMode = false }) => {
  const expirationInDays = (trialEnd: IsoDateTimeString) => {
    if (!trialEnd) {
      return null;
    }

    const end = DateTime.fromISO(trialEnd).startOf('day');
    const now = DateTime.utc().setZone(end.zone).startOf('day');
    const days = Math.round(end.diff(now, 'days').days);
    return days < 0 ? 'has ended' : days == 0 ? 'ends today' : `ends in ${days} day` + (days > 1 ? 's' : '');
  };

  return (
    <>
      {sideMenuMode && subscription.status === SubscriptionStatus.Trial && (
        <IonHeader className="test-header">
          <IonToolbar color="warning">
            <div className="center-toolbar">
              <IonChip color="dark" className="text-lg">
                &nbsp; Free Trial&nbsp; &nbsp;
              </IonChip>
            </div>
          </IonToolbar>
        </IonHeader>
      )}

      {!sideMenuMode && subscription.status === SubscriptionStatus.Trial && (
        <IonHeader className="test-header">
          <IonToolbar color="warning">
            <div className="center-toolbar">
              <IonChip color="dark" className="text-lg">
                &nbsp; Your free trial {expirationInDays(subscription.trialEndsAt)}. &nbsp;
                <a
                  href={`${process.env.REACT_APP_BASE_URL}/subscription/create/${organizationId}/${subscription.createToken}`}
                >
                  <u>See plans and pricing &gt;</u>
                </a>
                &nbsp;
              </IonChip>
            </div>
          </IonToolbar>
        </IonHeader>
      )}
    </>
  );
};
