import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext, Area } from 'App';
import { AppPage, AppPageAuthenticationRequirement, AppPageSideMenuMode } from 'components/AppPage';
import {
  QueryType,
  GetContactsQuery,
  PagedQueryView,
  ContactRowView,
  GetContactsQuerySort,
  CrmType,
  TrackingDomainSetupStatus
} from 'contracts/contracts.shared';
import { PageHeader } from 'components/PageHeader';
import { FunctionsApiError } from 'common/firebase/functionsApi';
import { filter, ellipsisVertical, chevronForwardOutline, closeCircle } from 'ionicons/icons';
import Avatar from 'react-avatar';
import { AlphabetFilter } from 'components/AlphabetFilter';
import { getAreaSettings } from 'common/areas';
import { isoDateToTimeAgo } from 'common/helpers';
import { ErrorBox } from 'components/ErrorBox';

const ContactActivityModal: React.FC<{
  contactId: string;
  contactToken: string;
  crmId: string;
  onClose: () => void;
}> = ({ contactId, contactToken, crmId, onClose }) => {
  const appContext = useContext(AppContext);
  const [visible, setVisible] = useState(true);

  const closed = () => {
    setVisible(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    appContext.userSettings && (
      <IonModal isOpen={visible} backdropDismiss={false} cssClass="modal-center-content">
        <IonHeader className="main-header">
          <IonToolbar color="primary">
            <IonTitle>Contact Activity Details</IonTitle>
            {appContext.userSettings.organization.crm === CrmType.Pipedrive && (
              <>
                <IonButton
                  slot="end"
                  href={`https://${appContext.userSettings.organization.pipedrive.subdomain}.pipedrive.com/person/${crmId}`}
                  target="_blank"
                >
                  Open in Pipedrive
                </IonButton>
                <IonIcon icon={null} slot="end" />
              </>
            )}

            <IonButton onClick={closed} slot="end">
              Close
            </IonButton>
            <IonIcon icon={null} slot="end" />
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div
            style={{ display: 'table', emptyCells: 'show', borderCollapse: 'collapse', width: '100%', height: '100%' }}
          >
            <div style={{ display: 'table-row', height: '100%', overflow: 'hidden' }}>
              <iframe
                title={`Contact Activity: ${contactId}`}
                style={{ width: '100%', height: '100%', margin: '0', padding: '0', border: 'none', display: 'block' }}
                src={`/activity/${appContext.userSettings.organization.id}/contacts/${contactId}/${contactToken}?modal=true`}
              />
            </div>
          </div>
        </IonContent>
      </IonModal>
    )
  );
};

const ContactsSkeleton: React.FC = () => {
  const listItems = Array.from(Array(10).keys()).map((item) => {
    return (
      <IonItem key={item}>
        <IonAvatar slot="start">
          <Avatar name="" size="48" round={true} maxInitials={2} />
        </IonAvatar>
        <IonLabel>
          <IonText>
            <IonSkeletonText animated={true} style={{ width: '60%' }} /> <br />
            <IonSkeletonText animated={true} style={{ width: '75%' }} />
          </IonText>
        </IonLabel>
        <IonButton fill="clear">
          <IonIcon icon={ellipsisVertical} slot="icon-only" />
        </IonButton>
      </IonItem>
    );
  });

  return <IonList>{listItems}</IonList>;
};

const ContactsList: React.FC<{ items: ContactRowView[]; onItemClick: (item: ContactRowView) => void }> = ({
  items,
  onItemClick
}) => {
  const noItems = (
    <IonItem key={'none'} lines="none">
      <IonText className="text-lg text-center">No contacts found.</IonText>
    </IonItem>
  );

  if (!items || items.length === 0) {
    return <IonList>{noItems}</IonList>;
  }

  const listItems = items.map((item) => {
    return (
      <IonItem key={item.id} lines="full" onClick={() => onItemClick(item)} style={{ cursor: 'pointer' }}>
        <IonAvatar slot="start">
          <Avatar name={item.firstName + ' ' + item.lastName} size="48" round={true} maxInitials={2} />
        </IonAvatar>

        <IonLabel>
          <IonText className="text-lg">
            {item.lastName}, {item.firstName}
          </IonText>
          <br />
          <IonBadge className="text-xs text-weight-normal " color="light" slot="end">
            {item.business || 'No organization'}
          </IonBadge>
          &nbsp;
          {item.lastAlertAt && (
            <IonBadge className="text-xs text-weight-normal " color="warning" slot="end">
              Last alert {isoDateToTimeAgo(item.lastAlertAt, '')}
            </IonBadge>
          )}
        </IonLabel>
        {/* <IonButton fill="clear" onClick={(e) => onItemClick(item)}>
          <IonIcon icon={ellipsisVertical} slot="icon-only" />
        </IonButton> */}
      </IonItem>
    );
  });

  return <IonList>{listItems}</IonList>;
};

export const ContactsPage: React.FC = () => {
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [contactsData, setContactsData] = useState<PagedQueryView<ContactRowView, GetContactsQuerySort>>();
  const [contacts, setContacts] = useState<ContactRowView[]>([]);
  const [totalContacts, setTotalContacts] = useState<number>();
  const [selectedContact, setSelectedContact] = useState<ContactRowView>(null);
  const [sortFilter, setSortFilter] = useState<{ sort?: GetContactsQuerySort; letterFilter?: string; at: Date }>();
  const [isInfiniteScrollDisabled, setIsInfiniteScrollDisabled] = useState<boolean>(true);
  const [isFilterHidden, setIsFilterHidden] = useState<boolean>(false);
  const areaSettings = getAreaSettings(Area.Contacts);
  const onValidatePermissions = (): boolean => {
    return true;
  };

  const onLoad = async () => {
    setIsLoading(true);
    setErrors([]);
    setSortFilter({
      sort:
        appContext.userSettings.organization.defaultTrackingDomain?.setupStatus === TrackingDomainSetupStatus.Completed
          ? GetContactsQuerySort.MostRecentAlert
          : GetContactsQuerySort.LastName,
      letterFilter: null,
      at: new Date()
    });
    setIsFilterHidden(false);
  };

  const loadPageData = useCallback(
    async (page: number, sort?: GetContactsQuerySort, letterFilter?: string) => {
      const showLoading = page === 1; // No loading on infinite scroll
      setErrors([]);
      if (showLoading) {
        setIsLoading(true);
      }
      try {
        const data = await appContext.functions.executeQuery<
          GetContactsQuery,
          PagedQueryView<ContactRowView, GetContactsQuerySort>
        >(QueryType.GetContactsQuery, {
          page,
          filter: letterFilter,
          sort: sort
        });
        setContactsData(data);
        setTotalContacts(data.totalRecords);
        setContacts((c) => [...c, ...data.records]);
        setIsInfiniteScrollDisabled(!data.hasNextPage);

        setIsLoading(false);
      } catch (err) {
        setErrors((err as FunctionsApiError)?.errors || []);
        setIsLoading(false);
      }
    },
    [appContext.functions]
  );

  useEffect(() => {
    if (!sortFilter) {
      return;
    }
    setTotalContacts(null);
    setContactsData(null);
    setContacts([]);
    setSelectedContact(null);
    setIsInfiniteScrollDisabled(true);

    async function load() {
      await loadPageData(1, sortFilter?.sort, sortFilter?.letterFilter);
    }

    load();
  }, [sortFilter, loadPageData]);

  const onLoadNextPage = async (evt: CustomEvent<void>) => {
    if (contactsData) {
      await loadPageData(contactsData.page + 1, sortFilter?.sort, sortFilter?.letterFilter);
    }
    (evt.target as HTMLIonInfiniteScrollElement).complete();
  };

  const onLetterFilterChanged = (letterFilter?: string) => {
    setSortFilter((s) => {
      return { ...s, ...{ letterFilter, at: new Date() } };
    });
  };

  const onSortChanged = (sort: GetContactsQuerySort) => {
    setSortFilter((s) => {
      return { ...s, ...{ sort, at: new Date() } };
    });
  };

  return (
    <AppPage
      appContext={appContext}
      sideMenuMode={AppPageSideMenuMode.Show}
      authenticationRequirement={AppPageAuthenticationRequirement.AuthenticatedUser}
      onValidatePermissions={onValidatePermissions}
      onLoad={onLoad}
      isLoading={isLoading}
    >
      <PageHeader title="Contact Activity" />
      <IonContent>
        <IonCard className="page-content">
          <IonCardContent>
            <IonToolbar slot="start">
              <IonText>
                <span>
                  <IonChip outline={true} className="text-xl">
                    <IonIcon icon={areaSettings.icon} />
                    <IonLabel>Contacts</IonLabel>
                  </IonChip>
                  {sortFilter?.letterFilter && (
                    <>
                      <IonIcon icon={chevronForwardOutline} size="medium" />
                      <IonChip outline={true} className="text-lg">
                        <IonLabel>
                          {!sortFilter?.letterFilter ? 'All' : 'Last name: "' + sortFilter.letterFilter + '"'}
                        </IonLabel>
                        {sortFilter?.letterFilter && (
                          <IonIcon icon={closeCircle} onClick={() => onLetterFilterChanged(null)} />
                        )}
                      </IonChip>
                    </>
                  )}
                  &nbsp;
                </span>

                <br />
                <div className="text-xs text-light-weight">
                  &nbsp; &nbsp;Searching {totalContacts ? totalContacts : '...'} active contacts synced with Pipedrive.
                </div>
              </IonText>
              <IonSelect
                interface="popover"
                value={sortFilter?.sort || GetContactsQuerySort.LastName}
                placeholder="Select One"
                onIonChange={(e) => onSortChanged(e.detail.value)}
                slot="end"
                style={{ border: 'solid 1px #eeeeee', paddingTop: '4px', paddingBottom: '4px' }}
              >
                <IonSelectOption value={GetContactsQuerySort.LastName}>Sort: Last name</IonSelectOption>
                <IonSelectOption value={GetContactsQuerySort.MostRecentAlert}>
                  Sort: Last activity alert
                </IonSelectOption>
              </IonSelect>
              <IonIcon slot="end" icon={null} />
              <IonButton slot="end" color="white" size="small" onClick={() => setIsFilterHidden((f) => !f)}>
                <IonIcon icon={filter} />
              </IonButton>
              {/* <IonIcon slot="end" icon={null} />
              <IonButton slot="end" color="white" size="small" routerLink={'/create'}>
                <IonIcon icon={add} /> Add a Contact
              </IonButton> */}
            </IonToolbar>
            <IonItemDivider className="divider-compressed" />
            {!isFilterHidden && (
              <AlphabetFilter selectedLetter={sortFilter?.letterFilter} onSelect={(f) => onLetterFilterChanged(f)} />
            )}
            <ErrorBox errors={errors} onClearErrors={() => setErrors([])} />
            {isLoading && <ContactsSkeleton />}
            {!isLoading && contacts && (
              <>
                <ContactsList items={contacts} onItemClick={setSelectedContact} />
              </>
            )}
          </IonCardContent>
        </IonCard>

        {selectedContact && (
          <ContactActivityModal
            crmId={selectedContact.crmId}
            contactId={selectedContact.id}
            contactToken={selectedContact.token}
            onClose={() => setSelectedContact(null)}
          />
        )}

        {!isInfiniteScrollDisabled && (
          <IonInfiniteScroll
            threshold="100px"
            disabled={isInfiniteScrollDisabled}
            onIonInfinite={onLoadNextPage}
            className="page-content-width"
          >
            <IonInfiniteScrollContent loadingText="Loading more..." />
          </IonInfiniteScroll>
        )}
      </IonContent>
    </AppPage>
  );
};
