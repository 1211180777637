import { IonLoading, IonPage, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { IAppContext } from 'App';
import { CrmType, TaskStatus } from 'contracts/contracts.shared';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';

export enum AppPageSideMenuMode {
  Show = 'show',
  Hide = 'hide'
}

export enum AppPageAuthenticationRequirement {
  Any = 'any',
  AuthenticatedAccount = 'authenticated_account',
  AuthenticatedUser = 'authenticated_user',
  UnAuthenticated = 'unauthenticated'
}

export interface AppPageValidatePermissionsParams {
  context: IAppContext;
  locationPath: string;
}

export interface AppPageProps {
  appContext: IAppContext;
  sideMenuMode: AppPageSideMenuMode;
  authenticationRequirement: AppPageAuthenticationRequirement;
  onLoad?: () => Promise<void> | undefined;
  onUnload?: () => Promise<void> | undefined;
  onValidatePermissions: (request: AppPageValidatePermissionsParams) => boolean;
  isLoading?: boolean;
  hideLoadingSpinner?: boolean;
}

export const AppPage: React.FC<AppPageProps> = ({
  appContext,
  sideMenuMode,
  authenticationRequirement,
  onLoad,
  onUnload,
  onValidatePermissions,
  children,
  isLoading,
  hideLoadingSpinner
}) => {
  const [isAccessible, setIsAccessible] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useIonViewWillEnter(async () => {
    if (
      authenticationRequirement === AppPageAuthenticationRequirement.AuthenticatedUser ||
      authenticationRequirement === AppPageAuthenticationRequirement.AuthenticatedAccount
    ) {
      if (!appContext.user) {
        history.push('/signin?r=' + location.pathname);
        return;
      }
    }

    if (authenticationRequirement === AppPageAuthenticationRequirement.AuthenticatedUser) {
      const startPath = '/setup/pipedrive';
      if (
        location.pathname !== startPath &&
        appContext.userSettings?.organization?.crm === CrmType.Pipedrive &&
        appContext.userSettings?.organization?.tasks?.initialSetup !== TaskStatus.Completed
      ) {
        history.push(startPath);
        return;
      }

      if (onValidatePermissions && !onValidatePermissions({ context: appContext, locationPath: location.pathname })) {
        history.push('/access-denied?r=' + location.pathname);
        return;
      }
    } else if (authenticationRequirement === AppPageAuthenticationRequirement.UnAuthenticated) {
      if (appContext.user) {
        history.push('/dashboard');
        return;
      }
    }
    appContext.setMenuHidden(sideMenuMode === AppPageSideMenuMode.Show ? false : true);
    setIsAccessible(true);
    if (onLoad) {
      await onLoad();
    }
  }, [appContext, location, history, onValidatePermissions, authenticationRequirement, sideMenuMode, onLoad]);

  useIonViewWillLeave(async () => {
    if (onUnload) {
      await onUnload();
    }
  }, []);

  return (
    <IonPage>
      {isAccessible && children}
      {isLoading === true && (
        <IonLoading isOpen={isLoading} spinner={hideLoadingSpinner ? null : 'dots'} cssClass="custom-loading" />
      )}
    </IonPage>
  );
};
