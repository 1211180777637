import React, { useContext } from 'react';
import { AppContext, Area } from 'App';
import { FormField, FormMode, RadioGroupFieldValue, SelectFieldValue } from 'components/Form';

import { FormPage } from 'components/FormPage';
import { AppPageValidatePermissionsParams } from 'components/AppPage';
import {
  CommandType,
  GetTrackingDetailsQuery,
  GetUsersQuery,
  QueryType,
  TrackingDetailsView,
  UpdateTrackingSettingsCommand,
  UserRecord,
  UserRole
} from 'contracts/contracts.shared';
import { getAreaSettings } from 'common/areas';
import { useHistory } from 'react-router';

interface TrackingSettingsFormDefinitionData {
  users: UserRecord[];
}

interface TrackingSettingsFormValues {
  alertFrequency: RadioGroupFieldValue;
  defaultAlertUser: SelectFieldValue;
}

const trackingSettingsForm = {
  definition: (mode: FormMode, data: TrackingSettingsFormDefinitionData): FormField[] => {
    return [
      {
        type: 'radioGroup',
        definition: {
          name: 'alertFrequency',
          label: 'Alert Frequency',
          allowEmptySelection: true,
          iconMode: 'hidden'
        },
        options: [
          {
            label: 'Max of 1 alert per contact every 30 minutes',
            value: '30'
          },
          {
            label: 'Max of 1 alert per contact every hour',
            value: '60'
          },
          {
            label: 'Max of 1 alert per contact every 4 hours',
            value: '240'
          },
          {
            label: 'Max of 1 alert per contact every 8 hours',
            value: '480'
          },
          {
            label: 'Max of 1 alert per contact every 24 hours',
            value: '1440'
          }
        ]
      },
      {
        type: 'select',
        definition: {
          name: 'defaultAlertUser',
          label: 'Default User to Assign Alerts',
          header: 'Default Alert User',
          message: 'The user to assign contact alerts to in the CRM if there is no owner.',
          placeholder: 'Select a User',
          iconMode: 'hidden'
        },
        items: data.users.map((m) => ({
          label: m.name,
          value: m.id + '',
          enabled: true
        }))
      }
    ];
  },
  definitionData: (users: UserRecord[]): TrackingSettingsFormDefinitionData => {
    return { users };
  },
  values: (settings: TrackingDetailsView | undefined): TrackingSettingsFormValues => {
    return {
      alertFrequency: {
        selectedValue: settings?.contactAlerts?.minMinutesBetween?.toString() ?? '30'
      },
      defaultAlertUser: {
        value: settings?.contactAlerts?.defaultUser?.id || ''
      }
    };
  }
};

export const TrackingSettingsUpdatePage: React.FC = () => {
  const appContext = useContext(AppContext);
  const areaSettings = getAreaSettings(Area.Tracking);
  const history = useHistory();

  // Form Page Usage Notes
  // Pass params like so to determine create/edit mode
  // const { id } = useParams<FormCreateUpdatePageRouteParams>();
  const onEnter = async () => {
    let definition: FormField[] = [];
    let definitionData: TrackingSettingsFormDefinitionData;
    let values: TrackingSettingsFormValues;

    try {
      const users = await appContext.functions.executeQuery<GetUsersQuery, UserRecord[]>(QueryType.GetUsersQuery, {});
      const trackingDetails = await appContext.functions.executeQuery<GetTrackingDetailsQuery, TrackingDetailsView>(
        QueryType.GetTrackingDetailsQuery,
        {}
      );

      if (!trackingDetails) {
        history.push(areaSettings.url);
        return;
      }

      definitionData = trackingSettingsForm.definitionData(users);
      definition = trackingSettingsForm.definition(FormMode.Update, definitionData);
      values = trackingSettingsForm.values(trackingDetails);
    } catch (err) {
      history.push(areaSettings.url);
      return;
    }

    return { definition, definitionData, values };
  };

  const onSubmit = async (formValues: any) => {
    const form = formValues as TrackingSettingsFormValues;

    const action = async () =>
      await appContext.functions.executeCommand<UpdateTrackingSettingsCommand, boolean>(
        CommandType.UpdateTrackingSettingsCommand,
        {
          defaultAlertUserId: form.defaultAlertUser.value || '',
          alertFrequencyMinutes: +(form.alertFrequency.selectedValue || '')
        }
      );

    try {
      await action();

      return {
        onSuccess: async () => {
          history.push(areaSettings.url);
          return;
        }
      };
    } catch (err) {
      return {
        errors: err?.errors || []
      };
    }
  };

  const onValidatePermissions = (request: AppPageValidatePermissionsParams): boolean => {
    const isAdmin = appContext.userSettings?.role === UserRole.Admin;
    return isAdmin;
  };

  return (
    <FormPage
      appContext={appContext}
      title="Update Web Activity Alert Settings"
      areaSettings={areaSettings}
      onEnter={onEnter}
      onSubmit={onSubmit}
      onValidatePermissions={onValidatePermissions}
    />
  );
};
