import {
  briefcaseOutline,
  keyOutline,
  peopleOutline,
  people,
  key,
  briefcase,
  cloudOutline,
  cloud,
  gridOutline,
  grid
} from 'ionicons/icons';
import { Area } from 'App';

export interface AreaSettings {
  title: string;
  singular: string;
  plural: string;
  icon: string;
  iconOutline: string;
  url: string;
}

export const getAreaMenuItem = (area: Area) => {
  const areaSettings = getAreaSettings(area);
  return {
    title: areaSettings.title,
    url: areaSettings.url,
    icon: areaSettings.iconOutline
  };
};

export const getAreaSettings = (area: Area): AreaSettings => {
  switch (area) {
    case Area.Account:
      return {
        title: 'Account',
        singular: 'Account',
        plural: 'Accounts',
        icon: briefcase,
        iconOutline: briefcaseOutline,
        url: '/settings/account'
      };
    case Area.Tracking:
      return {
        title: 'Tracking & Alerts',
        singular: 'Tracking & Alerts',
        plural: 'Tracking & Alerts ',
        icon: cloud,
        iconOutline: cloudOutline,
        url: '/settings/tracking'
      };
    case Area.Users:
      return {
        title: 'Users',
        singular: 'User',
        plural: 'Users',
        icon: key,
        iconOutline: keyOutline,
        url: '/users'
      };
    case Area.Contacts:
      return {
        title: 'Contact Activity',
        singular: 'Contact',
        plural: 'Contacts',
        icon: people,
        iconOutline: peopleOutline,
        url: '/contacts'
      };

    case Area.Dashboard:
      return {
        title: 'Dashboard',
        singular: 'Item',
        plural: 'Items',
        icon: grid,
        iconOutline: gridOutline,
        url: '/dashboard'
      };
  }
};
