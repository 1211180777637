import React, { useContext, useState } from 'react';
import { IonContent, IonBadge, IonIcon, IonCol, IonRow, IonText, IonTitle, IonButton } from '@ionic/react';
import { AppContext } from 'App';
import {
  AppPage,
  AppPageAuthenticationRequirement,
  AppPageSideMenuMode,
  AppPageValidatePermissionsParams
} from 'components/AppPage';
import { ErrorBox } from 'components/ErrorBox';
import Avatar from 'react-avatar';
import { FunctionsApiError } from 'common/firebase/functionsApi';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import ReactTooltip from 'react-tooltip';
import StarsIcon from '@material-ui/icons/Stars';
import TodayIcon from '@material-ui/icons/Today';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import { gridOutline, link, navigateCircleOutline, notificationsOutline, search } from 'ionicons/icons';
import {
  ContactActivitySummaryView,
  ContactTrackingActivityHistoryView,
  DeviceType,
  GetContactActivitySummaryQuery,
  GetContactTrackingActivityHistoryQuery,
  QueryType
} from 'contracts/contracts.shared';
import { isoDateToTimeAgo, isoDateToTime, isoDateToDay, isoDateToWeekDay } from 'common/helpers';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { PageHeaderCenter } from 'components/PageHeaderCenter';
import { AnnouncementBar } from 'components/AnnouncementBar';
import { useParams } from 'react-router';

const useContactActivityStyles = createUseStyles({
  // cover: {
  //   height: '1px',
  //   backgroundPosition: 'center',
  //   backgroundSize: 'cover',
  //   backgroundRepeat: 'no-repeat',
  //   opacity: 1,
  //   backgroundImage: 'url(/assets/cover-black.jpg)'
  // },
  contactIcon: {
    boxShadow: ' 0 0 0 4px #1976d2, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05)'
  },
  contactHeader: {
    marginTop: '-36px',
    zIndex: 100
  },
  heatmapContainer: {
    '& .react-calendar-heatmap': {
      height: '250px'
    }
  },
  timelineContainer: {
    '& .vertical-timeline-element-content': {
      color: 'black',
      '& p': {
        margin: '0 !important',
        lineHeight: 'normal !important'
      }
    },
    '& .vertical-timeline-element-date': {
      fontWeight: '800 !important',
      fontSize: '18px !important',
      color: '#000000 !important'
    },
    '& .vertical-timeline.vertical-timeline-custom-line::before': {
      background: '#424242'
    },
    '& .vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--work .vertical-timeline-element-icon': {
      boxShadow: '0 0 0 4px #1976d2, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05)'
    },
    '& .vertical-timeline--animate .vertical-timeline-element-content.bounce-in': {
      visibility: 'visible',
      animation: 'custom-animation 0s !important;'
    },
    '& .vertical-timeline-element-title': {
      paddingBottom: '10px'
    },
    '& .MuiTimelineOppositeContent-root': {
      flex: 'none !important'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '12px !important'
    }
  }
});

interface ContactActivityPageParams {
  organizationId: string;
  contactId: string;
  contactToken: string;
}

export const ContactActivityPage: React.FC = () => {
  const appContext = useContext(AppContext);
  const [summary, setSummary] = useState<ContactActivitySummaryView>();
  const [webHistory, setWebHistory] = useState<ContactTrackingActivityHistoryView>();
  const [errors, setErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isInModal, setIsInModal] = useState<boolean>(false);
  const styles = useContactActivityStyles();
  const { organizationId, contactId, contactToken } = useParams<ContactActivityPageParams>();

  const resetForm = () => {
    setErrors([]);
    setIsSubmitting(false);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const listeners: Function[] = [];

  const onUnload = async () => {
    for (const l of listeners) {
      l();
    }
  };

  const onLoad = async () => {
    resetForm();
    setIsSubmitting(true);

    const urlParams = new URLSearchParams(location.search);
    setIsInModal(!urlParams.get('modal') ? false : true);

    try {
      const summaryData = await appContext.functions.executeQuery<
        GetContactActivitySummaryQuery,
        ContactActivitySummaryView
      >(QueryType.GetContactActivitySummaryQuery, {
        organizationId,
        contactId,
        contactToken
      });

      const webHistoryData = await appContext.functions.executeQuery<
        GetContactTrackingActivityHistoryQuery,
        ContactTrackingActivityHistoryView
      >(QueryType.GetContactTrackingActivityHistoryQuery, {
        organizationId,
        contactId,
        contactToken
      });

      setSummary(summaryData);
      setWebHistory(webHistoryData);
      setIsSubmitting(false);

      // const onContactChangedListener = appContext.firestore.doc(`/contacts/${match.params.contactId}`).onSnapshot(
      //   (docSnapshot) => {
      //     console.log('Received doc snapshot', docSnapshot.data());
      //     // ...
      //   },
      //   (err) => {
      //     console.log(`Encountered error: ${err}`);
      //   }
      // );
      // listeners.push(onContactChangedListener);
    } catch (err) {
      const typedErr = err as FunctionsApiError;
      setErrors(typedErr.errors);
      setIsSubmitting(false);
    }
  };

  const onValidatePermissions = (request: AppPageValidatePermissionsParams): boolean => {
    return true;
  };

  return (
    <AppPage
      appContext={appContext}
      sideMenuMode={AppPageSideMenuMode.Hide}
      authenticationRequirement={AppPageAuthenticationRequirement.Any}
      onLoad={onLoad}
      onUnload={onUnload}
      onValidatePermissions={onValidatePermissions}
      isLoading={isSubmitting}
    >
      <IonContent>
        {!isInModal && (
          <>
            {summary && (
              <AnnouncementBar organizationId={summary.organization.id} subscription={summary.subscription} />
            )}

            <PageHeaderCenter
              organizationName={summary?.organization?.name}
              buttons={
                <>
                  <IonButton
                    slot="end"
                    href={`${process.env.REACT_APP_BASE_URL}/dashboard`}
                    target="_blank"
                    size="small"
                  >
                    <IonIcon icon={gridOutline} slot="icon-only" />
                  </IonButton>
                  <IonButton
                    slot="end"
                    href={`${process.env.REACT_APP_BASE_URL}/contacts`}
                    target="_blank"
                    size="small"
                  >
                    <IonIcon icon={search} slot="icon-only" />
                  </IonButton>
                </>
              }
            />
          </>
        )}

        <div style={{ maxWidth: '600px', margin: 'auto' }}>
          <ErrorBox errors={errors} onClearErrors={() => setErrors([])} />
        </div>
        <div className="vertical-layout vertical-layout-compressed">
          {isInModal && (
            <>
              <br />
              <br />
            </>
          )}

          <div className="centered-container">
            {summary && webHistory && (
              <>
                {!isInModal && (
                  <>
                    <div className="main-header" style={{ paddingTop: '5px' }}>
                      <IonTitle color="white" size="small">
                        Contact Activity Details
                      </IonTitle>
                    </div>
                  </>
                )}
                <div className={classNames(styles.contactHeader, 'center-text')}>
                  <div>
                    <Avatar
                      name={summary.contact.name}
                      size="84"
                      round={true}
                      maxInitials={2}
                      className={styles.contactIcon}
                    />
                    &nbsp; <h2>{summary.contact.name}</h2>
                  </div>
                  <div className="text-subheading ">{summary.contact.businessName || ''}</div> <br />
                </div>
                <div className="panel panel-padded">
                  <div className="panel">
                    <div className="stat-grid stat-grid-extra ">
                      <IonRow className="ion-text-center ion-align-items-end">
                        <IonCol>
                          <div className="stat-number">{summary.totals.views}</div>
                          <IonText color="medium">
                            <div className="stat-label">Website Views</div>
                          </IonText>
                        </IonCol>
                        <IonCol>
                          <div className="stat-number">{summary.totals.alerts}</div>
                          <IonText color="medium">
                            <div className="stat-label">Activity Alerts</div>
                          </IonText>
                        </IonCol>
                        <IonCol>
                          <div className="stat-number">
                            Pipedrive{' '}
                            <a
                              href={`https://${summary.organization.crmBaseUrl}.pipedrive.com/person/${summary.contact.crmId}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <IonIcon icon={link} slot="icon-only" />
                            </a>
                          </div>
                          <IonText color="medium">
                            <div className="stat-label">Synced with CRM</div>
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow className="ion-text-center ion-align-items-end border-top">
                        <IonCol>
                          <div className="stat-number">{isoDateToTimeAgo(summary.contact.lastAlertAt, '-')}</div>
                          <IonText color="medium">
                            <div className="stat-label">Last Activity Alert</div>
                          </IonText>
                        </IonCol>
                        <IonCol>
                          <div className="stat-number">{isoDateToDay(summary.contact.createdAt, '-')}</div>
                          <IonText color="medium">
                            <div className="stat-label">Tracking Started</div>
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </div>
                  </div>
                </div>
                <h2 className="center-text">Web Activity History</h2>
                <h5 className="center-text text-md text-light-weight">
                  This person visited your website on {summary.dailyViews.records.length} out of the last 90 days.
                </h5>
                <div className={classNames(styles.heatmapContainer, 'center-text')}>
                  <CalendarHeatmap
                    startDate={summary.dailyViews.start}
                    endDate={summary.dailyViews.end}
                    tooltipDataAttrs={(value: any) => {
                      return {
                        'data-tip': `${value.count || 0} web pages viewed ${
                          value.date ? 'on ' + isoDateToDay(value.date, '') : ''
                        }.`
                      };
                    }}
                    values={summary.dailyViews.records.map((m) => {
                      return { date: m.day, count: m.count };
                    })}
                  />
                  <ReactTooltip />
                </div>
                <br />
                <h2 className="center-text">Web Activity Timeline</h2>
                <h5 className="center-text text-md text-light-weight">
                  Timezone is {summary.organization.timezoneName}.
                </h5>
                <div className={classNames(styles.timelineContainer)}>
                  <VerticalTimeline className="vertical-timeline-custom-line" layout="1-column">
                    {webHistory.activity.map((dayActivity) => (
                      <VerticalTimelineElement
                        iconStyle={{ background: '#8cc665', color: '#fff' }}
                        icon={<TodayIcon />}
                        key={'timeline' + dayActivity.day}
                      >
                        <h3 className="vertical-timeline-element-title">{isoDateToWeekDay(dayActivity.day, '')}</h3>

                        {dayActivity.domainActivity.map((dayDomainActivity) => (
                          <div key={'timeline' + dayDomainActivity.day + dayDomainActivity.domain}>
                            <p className="text-subheading">
                              <WebAssetIcon /> {dayDomainActivity.domain}
                            </p>

                            <Timeline align="left">
                              {dayDomainActivity.webActivity.map((dayDomainTrackingActivity) => (
                                <TimelineItem key={dayDomainTrackingActivity.id}>
                                  <TimelineOppositeContent>
                                    <Typography
                                      color="textSecondary"
                                      dangerouslySetInnerHTML={{
                                        __html: isoDateToTime(dayDomainTrackingActivity.created, '')
                                      }}
                                    />
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot variant="outlined">
                                      {dayDomainTrackingActivity.device === DeviceType.Computer && <DesktopMacIcon />}
                                      {dayDomainTrackingActivity.device === DeviceType.Mobile && <TabletMacIcon />}
                                    </TimelineDot>
                                    <TimelineConnector />
                                  </TimelineSeparator>
                                  <TimelineContent>
                                    <Typography>
                                      {dayDomainTrackingActivity.path} <br />
                                      {dayDomainTrackingActivity.trackingStartedSource && (
                                        <>
                                          <IonBadge color="none">
                                            <IonIcon icon={navigateCircleOutline} size="medium" /> Tracking Started:{' '}
                                            {dayDomainTrackingActivity.trackingStartedSource.name}
                                          </IonBadge>
                                          &nbsp;
                                        </>
                                      )}
                                      {dayDomainTrackingActivity.triggeredAlert && (
                                        <IonBadge color="warning">
                                          <IonIcon icon={notificationsOutline} /> Alert Sent
                                        </IonBadge>
                                      )}
                                    </Typography>
                                  </TimelineContent>
                                </TimelineItem>
                              ))}
                            </Timeline>
                          </div>
                        ))}
                      </VerticalTimelineElement>
                    ))}
                    <VerticalTimelineElement iconStyle={{ background: '#8cc665', color: '#fff' }} icon={<StarsIcon />}>
                      <h3 className="vertical-timeline-element-title">
                        First synced on {isoDateToWeekDay(summary.contact.createdAt, '-')}
                      </h3>
                    </VerticalTimelineElement>
                  </VerticalTimeline>
                </div>
              </>
            )}
          </div>
        </div>
      </IonContent>
    </AppPage>
  );
};
