import firebase from 'firebase/app';
import 'firebase/analytics';

export class AnalyticsApi {
  // Notes:
  // 1. Must use supported logevent signatures
  // See https://firebase.google.com/docs/reference/js/firebase.analytics.Analytics

  private analytics: firebase.analytics.Analytics;
  constructor(fbAnalytics: firebase.analytics.Analytics) {
    this.analytics = fbAnalytics;
  }

  public setAccountId(id: string) {
    this.analytics.setUserId(id);
  }

  public error(err: Error) {
    if (!err) return;
    this.analytics.logEvent('exception', { key: err?.name, description: err?.message, fatal: false });
  }
}
