import { CommandRequest, QueryRequest, CommandType, QueryType, ErrorReason } from 'contracts/contracts.shared';
import firebase from 'firebase/app';

export enum FunctionsApiErrorType {
  InvalidArgument = 'invalid-argument',
  PermissionDenied = 'permission-denied',
  UnAuthenticated = 'unauthenticated',
  NotFound = 'not-found',
  Internal = 'internal',
  Unknown = 'unknown'
}

export interface FunctionsApiError {
  referenceId: string;
  type: FunctionsApiErrorType;
  reason: ErrorReason;
  errors: string[];
  innerError: any;
}

export class FunctionsApi {
  // Notes:
  // 1) Must wrap calls in promises to to fb library
  // See https://github.com/firebase/firebase-js-sdk/issues/1881
  private functions: firebase.functions.Functions;
  // private organizationId: string;
  constructor(fbFunctions: firebase.functions.Functions) {
    this.functions = fbFunctions;
  }

  // public setOrganizationId(id: string | undefined) {
  //   this.organizationId = id;
  // }

  public async executeCommand<TCommand, TResult>(
    type: CommandType,
    command: TCommand,
    overrideOrganizationId: string = null // Used in special cases only
  ): Promise<TResult> {
    const request: CommandRequest = {
      type,
      command: command
      // organizationId: overrideOrganizationId || this.organizationId || null
    };

    return await this.httpsCallableResult('executeAction', request);
  }

  public async executeQuery<TQuery, TResult>(
    type: QueryType,
    query: TQuery,
    overrideOrganizationId: string = null // Used in special cases only
  ): Promise<TResult> {
    const request: QueryRequest = {
      type,
      query: query
      // organizationId: overrideOrganizationId || this.organizationId || null
    };

    return await this.httpsCallableResult('executeAction', request);
  }

  private async httpsCallableResult(name: string, data: QueryRequest | CommandRequest): Promise<any> {
    const fn = this.functions.httpsCallable(name, {});
    return new Promise((resolve, reject) => {
      return fn(data)
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          // Codes: https://firebase.google.com/docs/reference/functions/providers_https_.html#functionserrorcode
          const ex = { type: err.code, referenceId: err.details?.referenceId || null } as FunctionsApiError;

          switch (ex.type) {
            case FunctionsApiErrorType.InvalidArgument:
              ex.reason = err.message;
              ex.errors = err.details?.errors || [];
              break;
            case FunctionsApiErrorType.NotFound:
              ex.errors = [
                '<strong>Not Found</strong><br/>Double check the address and try again.' +
                  (ex?.referenceId ? `<br/>Reference code: ${ex.referenceId}.` : '')
              ];
              break;
            case FunctionsApiErrorType.UnAuthenticated:
            case FunctionsApiErrorType.PermissionDenied:
              ex.errors = [
                '<strong>Permission Denied</strong><br/>' +
                  (ex?.referenceId ? `<br/>Reference code: ${ex.referenceId}` : '')
              ];
              break;
            default:
              ex.errors = [
                'An unexpected error has occurred. Please try again.<br/>' +
                  (ex?.referenceId ? `Reference code: ${ex.referenceId}.` : '')
              ];
              break;
          }

          ex.innerError = err;

          reject(ex);
        });
    });
  }
}
