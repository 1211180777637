import { IonButton, IonButtons } from '@ionic/react';
import React from 'react';

export const AlphabetFilter: React.FC<{ selectedLetter?: string; onSelect: (letter: string) => void }> = ({
  selectedLetter,
  onSelect
}) => {
  const letterSelected = (letter: string) => {
    if (onSelect) {
      onSelect(letter);
    }
  };
  return (
    <IonButtons>
      <div style={{ margin: '5px auto 0 auto', textAlign: 'center' }}>
        <IonButton
          color={!selectedLetter ? 'warning' : null}
          slot="start"
          size="small"
          onClick={() => letterSelected(null)}
        >
          All
        </IonButton>

        {[
          'A',
          'B',
          'C',
          'D',
          'E',
          'F',
          'G',
          'H',
          'I',
          'J',
          'K',
          'L',
          'M',
          'N',
          'O',
          'P',
          'Q',
          'R',
          'S',
          'T',
          'U',
          'V',
          'W',
          'X',
          'Y',
          'Z'
        ].map((item) => {
          return (
            <IonButton
              key={item}
              slot="start"
              size="small"
              color={selectedLetter === item ? 'warning' : null}
              onClick={() => letterSelected(item)}
            >
              {item}
            </IonButton>
          );
        })}
      </div>
    </IonButtons>
  );
};
