import React, { useContext, useState } from 'react';
import {
  IonContent,
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonBadge,
  IonCheckbox,
  IonIcon
} from '@ionic/react';

import { AppContext } from 'App';
import { useHistory } from 'react-router';
import { AppPage, AppPageAuthenticationRequirement, AppPageSideMenuMode } from 'components/AppPage';
import { ErrorBox } from 'components/ErrorBox';
import Avatar from 'react-avatar';
import { FunctionsApiError } from 'common/firebase/functionsApi';
import {
  CommandType,
  GetUserSettingsQuery,
  QueryType,
  SetupPipedriveOrganizationCommand,
  TrackingDomainSetupStatus,
  UserSettingsView
} from 'contracts/contracts.shared';

export const PipedriveSetupPage: React.FC = () => {
  const appContext = useContext(AppContext);
  const [errors, setErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [website, setWebsite] = useState<string>('');
  const [trackingAccepted, setTrackingAccepted] = useState<boolean>(false);
  const [trialAccepted, setTrialAccepted] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const history = useHistory();

  const resetForm = () => {
    setWebsite('');
    setTrackingAccepted(false);
    setTrialAccepted(false);
    setTermsAccepted(false);
    setErrors([]);
    setIsSubmitting(false);
  };

  const onLoad = async () => {
    const setupStatus = appContext.userSettings?.organization?.defaultTrackingDomain?.setupStatus;
    if (setupStatus && setupStatus !== TrackingDomainSetupStatus.None) {
      history.push('/dashboard');
      return;
    }
    resetForm();
  };

  const onSubmit = async (e: React.MouseEvent<HTMLIonButtonElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!trackingAccepted || !trialAccepted || !termsAccepted) {
      setErrors(['Please read and check all boxes below to proceed.']);
      setIsSubmitting(false);
      return;
    }

    try {
      await appContext.functions.executeCommand<SetupPipedriveOrganizationCommand, string>(
        CommandType.SetupPipedriveOrganizationCommand,
        { website }
      );
      const userSettings = await appContext.functions.executeQuery<GetUserSettingsQuery, UserSettingsView>(
        QueryType.GetUserSettingsQuery,
        {}
      );
      appContext.setUserSettings(userSettings);
      setIsSubmitting(false);
      history.push('/dashboard');
    } catch (err) {
      const typedErr = err as FunctionsApiError;
      setErrors(typedErr.errors);
      setIsSubmitting(false);
    }
  };

  const onValidatePermissions = (): boolean => {
    return true;
  };

  return (
    <AppPage
      appContext={appContext}
      sideMenuMode={AppPageSideMenuMode.Hide}
      authenticationRequirement={AppPageAuthenticationRequirement.AuthenticatedUser}
      onLoad={onLoad}
      onValidatePermissions={onValidatePermissions}
    >
      <IonContent>
        {appContext.userSettings &&
          appContext.userSettings?.organization?.defaultTrackingDomain?.setupStatus ===
            TrackingDomainSetupStatus.None && (
            <div className="vertical-layout vertical-layout-compressed bg-light">
              <h2 className="center-text">
                <img className="turbocharger-logo" alt="Logo" src={'/assets/logo-turbocharger.png'} />
              </h2>

              <div className="form-container" style={{ paddingTop: '48px' }}>
                <form>
                  <h5 className="center-text" style={{ marginTop: '0' }}>
                    <strong>
                      Complete Setup &amp; Start
                      <br /> Your Free 30 Day Trial.
                    </strong>
                  </h5>
                  <br />

                  <>
                    <div className="center-text">
                      <div className="profile-name subheading" style={{ marginBottom: '12px' }}>
                        <Avatar name={appContext.userSettings.name} size="24" round={true} maxInitials={2} />
                        &nbsp; {appContext.userSettings.name}
                      </div>

                      <div className="profile-text">
                        <IonBadge color="warning">Admin User</IonBadge>{' '}
                        <span className="badge-text" style={{ verticalAlign: 'top' }}>
                          {' '}
                          at{' '}
                        </span>
                        <IonBadge color="dark">{appContext.userSettings.organization.name}</IonBadge>
                      </div>
                    </div>

                    <br />

                    <ErrorBox errors={errors} onClearErrors={() => setErrors([])} />
                    <IonList>
                      <IonItem className="no-padding-start inner-padding-start ">
                        <IonLabel position="stacked" style={{ width: '100%' }}>
                          Enter your company&#39;s website
                        </IonLabel>

                        <IonInput
                          name="website"
                          type="text"
                          placeholder="Ex: www.company.com"
                          className="margin-top"
                          value={website}
                          onIonChange={(e) => setWebsite((e.target as HTMLInputElement)?.value)}
                        />
                      </IonItem>
                      <br />
                      <IonItem lines="none">
                        <IonCheckbox
                          name="trackingAccepted"
                          checked={trackingAccepted}
                          onIonChange={(e) => setTrackingAccepted(e.detail.checked)}
                        />
                        <IonIcon icon={null} />
                        <IonLabel className="ion-text-wrap">
                          I can the add the Turbocharger tracking code to this website or ask an IT person to do it.
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonCheckbox
                          name="trialAccepted"
                          checked={trialAccepted}
                          onIonChange={(e) => setTrialAccepted(e.detail.checked)}
                        />
                        <IonIcon icon={null} />
                        <IonLabel className="ion-text-wrap">
                          This trial is 100% free for 30 days. It requires no billing information, no commitment, and
                          cancels automatically.
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonCheckbox
                          name="termsAccepted"
                          checked={termsAccepted}
                          onIonChange={(e) => setTermsAccepted(e.detail.checked)}
                        />
                        <IonIcon icon={null} />
                        <IonLabel className="ion-text-wrap">
                          I accept the &nbsp;
                          <a href="https://www.turbochargerhq.com/terms" target="_blank" rel="noreferrer">
                            terms of service
                          </a>
                          .
                        </IonLabel>
                      </IonItem>
                    </IonList>

                    <br />

                    <IonButton
                      expand="block"
                      color="danger"
                      fill="solid"
                      className="ion-margin-bottom"
                      onClick={onSubmit}
                      disabled={isSubmitting}
                    >
                      Finish
                    </IonButton>
                  </>
                </form>
              </div>
            </div>
          )}
        <IonLoading isOpen={isSubmitting} spinner="dots" cssClass="custom-loading" />
      </IonContent>
    </AppPage>
  );
};
