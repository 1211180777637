import React, { useContext } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonButtons,
  IonIcon,
  IonMenuToggle,
  IonChip,
  IonRouterLink
} from '@ionic/react';
import { menu } from 'ionicons/icons';
import { TopMenu } from './TopMenu';
import { AppContext, IAppContext } from 'App';
import { SubscriptionStatus, TrackingDomainSetupStatus } from 'contracts/contracts.shared';
import { AnnouncementBar } from './AnnouncementBar';

export const PageHeader: React.FC<{ title: string }> = ({ title }) => {
  const appContext = useContext(AppContext);

  const showDefaultTrackingAnnouncement = (context: IAppContext) => {
    if (!context.userSettings) {
      return false;
    }
    if (context.userSettings.organization?.subscription?.status === SubscriptionStatus.Cancelled) {
      return false;
    }
    if (context.userSettings.organization?.defaultTrackingDomain?.setupStatus === TrackingDomainSetupStatus.Completed) {
      return false;
    }

    return true;
  };

  return (
    <>
      {showDefaultTrackingAnnouncement(appContext) && (
        <IonHeader className="test-header">
          <IonToolbar color="warning">
            <div className="center-toolbar">
              <IonChip color="dark" className="text-lg">
                &nbsp; Action Required: &nbsp;
                <IonRouterLink
                  routerLink={`/setup/tracking/${appContext.userSettings.organization.id}/${appContext.userSettings.organization.defaultTrackingDomain.token}`}
                >
                  <u>Setup tracking for your website.</u>
                </IonRouterLink>
                &nbsp;
              </IonChip>
            </div>
          </IonToolbar>
        </IonHeader>
      )}

      {appContext.userSettings?.organization && !showDefaultTrackingAnnouncement(appContext) && (
        <AnnouncementBar
          subscription={appContext.userSettings.organization.subscription}
          organizationId={appContext.userSettings.organization.id}
        />
      )}

      <IonHeader className="main-header">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuToggle>
              <IonButton>
                <IonIcon icon={menu} size="large" />
              </IonButton>
            </IonMenuToggle>
          </IonButtons>
          <IonTitle slot="start">{title}</IonTitle>
          <TopMenu />
        </IonToolbar>
      </IonHeader>
    </>
  );
};
