import {
  IonAvatar,
  IonBadge,
  IonCard,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonRouterLink,
  IonText
} from '@ionic/react';
import React, { useContext, useState } from 'react';
import { AppContext } from 'App';
import {
  AppPage,
  AppPageAuthenticationRequirement,
  AppPageSideMenuMode,
  AppPageValidatePermissionsParams
} from 'components/AppPage';
import { PageHeader } from 'components/PageHeader';
import {
  GetTrackingDetailsQuery,
  QueryType,
  TrackerParamValueType,
  TrackingDetailsView,
  UserRole
} from 'contracts/contracts.shared';
import { FunctionsApiError } from 'common/firebase/functionsApi';
import { ErrorBox } from 'components/ErrorBox';
import Avatar from 'react-avatar';
import { helpCircle } from 'ionicons/icons';

export const TrackingSettingsPage: React.FC = () => {
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [view, setView] = useState<TrackingDetailsView>();

  const onLoad = async () => {
    try {
      setIsLoading(true);
      const data = await appContext.functions.executeQuery<GetTrackingDetailsQuery, TrackingDetailsView>(
        QueryType.GetTrackingDetailsQuery,
        {}
      );
      setView(data);
      setIsLoading(false);
    } catch (err) {
      setErrors((err as FunctionsApiError)?.errors || []);
      setIsLoading(false);
    }
  };

  const onValidatePermissions = (request: AppPageValidatePermissionsParams): boolean => {
    return request.context.userSettings.role === UserRole.Admin;
  };
  return (
    <AppPage
      appContext={appContext}
      sideMenuMode={AppPageSideMenuMode.Show}
      authenticationRequirement={AppPageAuthenticationRequirement.AuthenticatedUser}
      onValidatePermissions={onValidatePermissions}
      onLoad={onLoad}
      isLoading={isLoading}
    >
      <PageHeader title="Tracking &amp; Alerts" />
      <IonContent>
        <IonCard className="page-content">
          <ErrorBox errors={errors} onClearErrors={() => setErrors([])} />
          {view && (
            <div className="panel panel-padded">
              <div className="panel">
                <h5 className="text-subheading-underline ">Tracking Domains</h5>
                <p>Tracking domains enable first-party tracking for your websites.</p>

                <IonList lines="none">
                  {view.trackingDomains.map((domain) => (
                    <IonItem key={domain.id}>
                      <IonAvatar slot="start" style={{ width: '64px', height: '64px' }}>
                        <Avatar name={domain.apex} size="64" round={false} maxInitials={1} color="black" />
                      </IonAvatar>

                      <IonText>
                        <div style={{ marginBottom: '1px', fontWeight: 500 }} className="text-lg">
                          {domain.id}
                        </div>
                        <div className="text-xs" style={{ marginBottom: '8px' }}>
                          Enables tracking code work on {domain.apex} or any of it&#39;s subdomains.
                        </div>

                        <span>
                          <IonBadge color="secondary">Setup {domain.setupStatus}</IonBadge>
                          &nbsp; &nbsp;
                          <IonRouterLink
                            routerLink={`/setup/tracking/${domain.organizationId}/${domain.token}`}
                            style={{ verticalAlign: 'top' }}
                          >
                            <u>View setup instructions</u>
                          </IonRouterLink>
                        </span>
                      </IonText>
                    </IonItem>
                  ))}
                </IonList>
              </div>
              <div className="panel">
                <h5 className="text-subheading-underline ">Tracking Sources</h5>
                <p>Tracking sources help you identify the reason a contact visited your website. </p>

                <IonList lines="none">
                  {view.trackingSources.map((source) => (
                    <IonItem key={source.param}>
                      <IonAvatar slot="start" style={{ width: '64px', height: '64px' }}>
                        <Avatar name={source.param} size="64" round={false} maxInitials={1} color="black" />
                      </IonAvatar>

                      <IonText>
                        <div style={{ marginBottom: '1px', fontWeight: 500 }} className="text-lg">
                          {source.name}
                        </div>
                        <div className="text-xs" style={{ marginBottom: '8px' }}>
                          #{source.param}-
                          {source.paramValueType === TrackerParamValueType.TrackingId ? 'TrackingID' : 'EmailMD5Hash'}
                        </div>
                        <div className="text-xs">
                          Add #{source.param}-
                          {source.paramValueType === TrackerParamValueType.TrackingId
                            ? 'TrackingID to the end of links to your website. TrackingID is in a contact custom field in Pipedrive.'
                            : 'EmailMD5Hash to the end of links to your website.'}
                          {source.paramValueType === TrackerParamValueType.EmailOrMd5 && (
                            <a
                              href={`${process.env.REACT_APP_MARKETING_BASE_URL}/pipedrive/guide#email_marketing`}
                              target="blank"
                            >
                              <u>Watch help video</u>
                            </a>
                          )}
                        </div>
                      </IonText>
                    </IonItem>
                  ))}
                </IonList>
              </div>
              <div className="panel">
                <h5 className="text-subheading-underline ">
                  Web Activity Alert Options &nbsp;{' '}
                  <IonRouterLink routerLink={`/settings/tracking/edit`} style={{ verticalAlign: 'top' }}>
                    <u>edit</u>
                  </IonRouterLink>
                </h5>
                <h6 className="data-label">Alert Frequency</h6>
                <div>
                  Send a maximum of 1 activity alert per contact every{' '}
                  {view.contactAlerts.minMinutesBetween > 60
                    ? Math.round(view.contactAlerts.minMinutesBetween / 60) + ' hours'
                    : view.contactAlerts.minMinutesBetween + ' minutes'}
                  .
                </div>
                <h6 className="data-label">Alert Method</h6>
                <div>Pipedrive</div>
                <h6 className="data-label">Default User to Assign Alerts</h6>
                <div className="text-xs" style={{ marginBottom: '8px' }}>
                  <IonIcon icon={helpCircle} /> Activity alert tasks are assigned to the Pipedrive user that owns the
                  contact.
                  <br />
                  This setting determines which user to assign the alerts to if there is no owner.
                </div>
                <div>{view.contactAlerts?.defaultUser?.name || 'None'}</div>
              </div>
            </div>
          )}
        </IonCard>
      </IonContent>
    </AppPage>
  );
};
