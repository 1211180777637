export enum CommandType {
  RegisterOrConnectToPipedriveOrganizationCommand = 'register_or_connect_to_pipedrive_organization',
  SetupPipedriveOrganizationCommand = 'setup_pipedrive_organization',
  UninstallPipedriveOrganizationCommand = 'uninstall_pipedrive_organization',
  SyncPipedriveResourceCommand = 'sync_pipedrive_resource',
  CreateSubscriptionCheckoutSessionCommand = 'create_subscription_checkout_session',
  CreateSubscriptionPortalSessionCommand = 'create_subscription_portal_session',
  RecordTrackingActivityCommand = 'record_web_activity',
  ProcessSesNotificationCommand = 'process_ses_notification',
  SetupTrackingDomainCommand = 'setup_tracking_domain',
  UpdateTrackingSettingsCommand = 'update_tracking_settings',
  StripeSubscriptionChangedCommand = 'stripe_subscription_changed',
  EnqueueDailyTasksCommand = 'enqueue_daily_tasks',
  UnsubscribeMailCommand = 'unsubscribe_mail'
}

export enum QueryType {
  GetAccountOwnerAuthTokenQuery = 'get_account_owner_auth_token',
  GetContactActivitySummaryQuery = 'get_contact_activity_summary',
  GetContactTrackingActivityHistoryQuery = 'get_contact_web_activity_history',
  GetContactsQuery = 'get_contacts_query',
  GetUserSettingsQuery = 'get_user_settings',
  GetUsersQuery = 'get_users',
  GetOrganizationAccountDetailsQuery = 'get_organization_account_details',
  GetOrganizationDailyLicenseUsageQuery = 'get_organization_daily_license_usage',
  GetSubscriptionEstimateQuery = 'get_subscription_estimate',
  GetTrackingDetailsQuery = 'get_tracking_details',
  GetDashboardQuery = 'get_dashboard',
  GetTrackingSettingsQuery = 'get_tracking_settings',
  GetTrackingDomainSetupQuery = 'get_tracking_domain_setup'
}

export enum ErrorReason {
  ValidationFailed = 'validation_failed',
  OrganizationInactive = 'organization_inactive',
  UserInactive = 'user_inactive',
  PersonProfileCodeInactive = 'person_profile_code_inactive',
  OrganizationSetupCompleted = 'organization_setup_completed',
  OrganizationSubscriptionExists = 'organization_subscription_exists'
}

export interface CommandRequest {
  type: CommandType;
  organizationId?: string;
  command: any;
}

export interface QueryRequest {
  type: QueryType;
  organizationId?: string;
  query: any;
}

export interface PagedQueryView<TRecord, TSort> {
  totalRecords?: number;
  filter?: string;
  page: number;
  pageSize: number;
  hasNextPage: boolean;
  sort: TSort;
  records: TRecord[];
}

// -------- Common --------
export type OmitStrict<T, K extends keyof T> = T extends any ? Pick<T, Exclude<keyof T, K>> : never;

export interface BaseRecord {
  id?: string;
}

export interface Timestamp {
  _seconds: number;
}

export type IsoDateTimeString = string;

export interface ImmutableRecord extends BaseRecord {
  createdByAccountId?: string;
  createdByUserId?: string;
  createdByExecutionId?: string;
  createdAt?: Timestamp;
}

export interface MutableRecord extends ImmutableRecord {
  updatedByAccountId?: string;
  updatedByUserId?: string;
  updatedByExecutionId?: string;
  updatedAt?: Timestamp;
}

export interface OrganizationIdRecord {
  organizationId: string;
}

export interface DeleteableRecord {
  isDeleted: boolean;
}

// -------- Data  --------
export interface DataRecord extends ImmutableRecord {
  organizationId?: string;
  type: DataType;
  data: string; // JSON. No index.
}

export enum DataType {
  MailTemplateData = 'mail_template_data'
}

// -------- Email  --------

export enum EmailChannelDeliverabilityStatus {
  Ok = 'ok',
  Probation = 'probation',
  Prohibited = 'prohibited'
}

export enum EmailChannelDeliverabilityStatusReason {
  New = 'new',
  Delivery = 'delivery',
  Spam = 'spam',
  HardBounce = 'hard_bounce',
  SoftBounce = 'soft_bounce',
  Drop = 'drop',
  NonExistent = 'non_existent',
  Unsubscribe = 'unsubscribe'
}

export interface UnsubscribeMailCommand {
  mailId: string;
  emailAddress: string;
}

// -------- Organization --------

export enum CrmType {
  Pipedrive = 'pipedrive'
}
export enum SubscriptionStatus {
  Trial = 'trial',
  Active = 'active',
  Cancelled = 'cancelled'
}

export enum SubscriptionAdjustmentMode {
  Automatic = 'automatic',
  Manual = 'manual'
}

export enum TaskStatus {
  New = 'new',
  InProgress = 'in_progress',
  Completed = 'completed',
  Failed = 'failed'
}

export interface TaskData {
  status: TaskStatus;
  updatedAt: Timestamp;
}

export interface OrganizationSubscriptionData {
  status: SubscriptionStatus;
  adjustmentMode: SubscriptionAdjustmentMode;
  trialEndsAt?: Timestamp;
  extPriceId?: string;
  extSubscriptionId?: string;
  extCustomerId?: string;
  billingStartedAt?: Timestamp;
  billingCancelAt?: Timestamp;
  emailAddress: string;
  emailDelivery: {
    status: EmailChannelDeliverabilityStatus;
    statusReason: EmailChannelDeliverabilityStatusReason;
    statusAt: Timestamp;
  };
  currentPeriodStartAt?: Timestamp;
  currentPeriodEndAt?: Timestamp;
  currentPeriodExtInvoiceId?: string;
  currentPeriodLicenseQuantity?: number;
  tokens: {
    create: string;
    manage: string;
  };
  updatedAt?: Timestamp;
}

export enum StripeSubscriptionChangeType {
  CheckoutCompleted = 'checkout_completed',
  SubscriptionCancelled = 'subscription_cancelled',
  InvoicePaid = 'invoice_paid',
  InvoiceUpcoming = 'invoice_upcoming'
}

export interface StripeCheckoutCompletedData {
  extRequestId: string;
  extEventId: string;
  extSubscriptionId: string;
  extCustomerId: string;
  extPaymentStatus: string;
  organizationId: string;
  currency: string;
  amountTotal: number;
  customerEmail: string;
  createdTs: number;
}

export interface StripeInvoiceUpcomingData {
  extRequestId: string;
  extEventId: string;
  extBillingReason: string;
  extCustomerId: string;
  extSubscriptionId: string;
  extLineItemId: string;
  extLineItemPriceId: string;
  lineItemQuantity: number;
  periodStartTs: number;
  periodEndTs: number;
  createdTs: number;
}

export interface StripeInvoicePaidData {
  extRequestId: string;
  extEventId: string;
  extInvoiceId: string;
  extBillingReason: string;
  extLineItemId: string;
  extLineItemPriceId: string;
  lineItemQuantity: number;
  amountPaid: number;
  amountRemaining: number;
  currency: string;
  extCustomerId: string;
  extSubscriptionId: string;
  periodStartTs: number;
  periodEndTs: number;
  createdTs: number;
}

export interface StripeSubscriptionCancelledData {
  extRequestId: string;
  extEventId: string;
  extCustomerId: string;
  extSubscriptionId: string;
  periodStartTs: number;
  periodEndTs: number;
  createdTs: number;
}

export interface StripeSubscriptionChangedCommand {
  type: StripeSubscriptionChangeType;
  checkoutCompleted?: StripeCheckoutCompletedData;
  invoicePaid?: StripeInvoicePaidData;
  invoiceUpcoming?: StripeInvoiceUpcomingData;
  subscriptionCancelled?: StripeSubscriptionCancelledData;
}

export interface PipedriveCustomFieldDefinition {
  id: number;
  key: string;
  createdAt: Timestamp;
}

export interface PipedriveCustomActivityDefinition {
  id: number;
  key: string;
  createdAt: Timestamp;
}

export interface PipedriveWebhookDefinition {
  id: number;
  createdAt: Timestamp;
}

export enum AuthTokenStatus {
  Ok = 'ok',
  Error = 'error',
  Revoked = 'revoked'
}

export interface PipedriveAuth {
  userId: string;
  token: string;
  tokenStatus: AuthTokenStatus;
  lastUsedAt: Timestamp;
}

export enum PipedriveIntegrationSetupStatus {
  Pending = 'pending',
  Verified = 'verified',
  Completed = 'completed'
}

export interface PipedriveIntegration {
  subdomain: string;
  customFields: {
    trackerField: PipedriveCustomFieldDefinition;
    urlField: PipedriveCustomFieldDefinition;
  };
  customActivity: PipedriveCustomActivityDefinition;
  webHooks: {
    person: PipedriveWebhookDefinition;
    user: PipedriveWebhookDefinition;
  };
  tasks: {
    contactImport: TaskData;
    contactSetup: TaskData;
    userImport: TaskData;
  };
  auth: PipedriveAuth;
}

export interface OrganizationTrackingData {
  sources: TrackingSourceDefinition[];
  defaultTrackingDomain: {
    id: string;
    apex: string;
    setupStatus: TrackingDomainSetupStatus;
  };
  contactAlerts: { minMinutesBetween: number; defaultUser: { id: string; crmId: string } };
}

export interface OrganizationRecord extends MutableRecord, DeleteableRecord {
  crm: CrmType;
  crmId: string;
  ownerId: string;
  name: string;
  language: string;
  country: string;
  timezone: string;
  subscription: OrganizationSubscriptionData;
  tracking?: OrganizationTrackingData;
  tasks: {
    initialSetup: TaskData;
    trialExpiring3DaysEmail?: TaskData;
    trialExpiredEmail?: TaskData;
    dailyScheduledTasks?: TaskData;
  };
}

export interface OrganizationCrmRecord extends MutableRecord {
  pipedrive?: PipedriveIntegration;
}

export interface GetAccountOwnerAuthToken {
  organizationId: string;
  credential: string;
}

export interface GetUserSettingsQuery {}

export interface GetUsersQuery {}

export interface GetTrackingDetailsQuery {}

export interface TrackingDetailsView {
  trackingDomains: TrackingDomainRecord[];
  trackingSources: TrackingSourceDefinition[];
  defaultTrackingDomain: {
    id: string;
    apex: string;
    setupStatus: TrackingDomainSetupStatus;
  };
  contactAlerts: {
    minMinutesBetween: number;
    defaultUser?: {
      id: string;
      name: string;
    };
  };
}

export interface GetOrganizationDailyLicenseUsageQuery {
  periodEndUtc?: IsoDateTimeString;
  periodStartUtc?: IsoDateTimeString;
}
export interface OrganizationDailyLicenseUsageView {
  dailyActiveUsers: {
    start: IsoDateTimeString;
    end: IsoDateTimeString;
    average: number;
    records: { day: IsoDateTimeString; count: number }[];
  };
}

export interface GetSubscriptionEstimateQuery {
  organizationId: string;
  token: string;
}

export interface SubscriptionEstimateView {
  organization: {
    id: string;
    name: string;
    crm: CrmType;
  };
  currency: string;
  unitPrice: string;
  unitCount: number;
  total: string;
}
export interface GetOrganizationAccountDetailsQuery {}

export interface OrganizationAccountDetailsView {
  organization: {
    id: string;
    name: string;
    crm: CrmType;
    timezone: string;
    pipedrive?: {
      subdomain: string;
    };
    createdAt: IsoDateTimeString;
  };
  tasks: {
    contactImport: TaskStatus;
    contactSetup: TaskStatus;
    userImport: TaskStatus;
  };
  subscription: {
    status: SubscriptionStatus;
    createToken: string;
    manageToken: string;
    trialEndsAt?: IsoDateTimeString;
    billingStartedAt?: IsoDateTimeString;
    billingEndedAt?: IsoDateTimeString;
    currentPeriodLicenseQuantity?: number;
    currentPeriodStartAt?: IsoDateTimeString;
    currentPeriodEndAt?: IsoDateTimeString;
    updatedAt?: IsoDateTimeString;
  };
  counters: {
    totalActiveContacts: number;
    totalActiveUsers: number;
    totalAlerts: number;
    totalWebViews: number;
  };
}
export interface GetDashboardQuery {}

export interface DashboardView {
  organization: {
    crm: CrmType;
    createdAt: IsoDateTimeString;
  };
  tasks: {
    contactImport: TaskStatus;
    contactSetup: TaskStatus;
    userImport: TaskStatus;
  };
  totals: {
    activeTrackingSources: number;
    activeContacts: number;
    activeUsers: number;
    alerts: number;
    webViews: number;
    lastAlertAt: IsoDateTimeString;
    alertsToday: number;
    webViewsToday: number;
  };
  dailyAlerts: {
    start: IsoDateTimeString;
    end: IsoDateTimeString;
    records: { day: IsoDateTimeString; count: number }[];
  };
  dailyWebViews: {
    start: IsoDateTimeString;
    end: IsoDateTimeString;
    records: { day: IsoDateTimeString; count: number }[];
  };
}

export enum TrackerType {
  Crm = 'crm',
  Custom = 'custom'
}

export enum TrackerUrlOption {
  Hash = 'hash',
  Query = 'query',
  QueryOrHash = 'queryOrHash'
}

export enum TrackerParamValueType {
  TrackingId = 'tracking_id',
  EmailOrMd5 = 'email_or_md5'
}

export interface TrackingSourceDefinition {
  name: string;
  type: TrackerType;
  urlOption: TrackerUrlOption;
  param: string;
  paramValueType: TrackerParamValueType;
  active: boolean;
}

export enum TrackingDomainSetupStatus {
  None = 'none',
  Pending = 'pending',
  Completed = 'completed'
}

export interface TrackingDomainRecord extends MutableRecord, OrganizationIdRecord {
  id?: string; // Tracking url: tracker.xyz.com
  token: string;
  apex: string; // Apex: xyz.com
  externalId?: string; // Netlify Id
  externalDeletedAt?: Timestamp;
  setupStatus: TrackingDomainSetupStatus;
  // subResources: []; // All by default
}

// export interface TrackingSettingsRecord extends MutableRecord {
//   id?: string; // OrganizationID
//   sources: TrackingSourceDefinition[];
//   defaultTrackingDomain: {
//     id: string;
//     apex: string;
//     setupStatus: TrackingDomainSetupStatus;
//   };
//   contactAlerts: { minMinutesBetween: number; defaultUser: { id: string; crmId: string } };
// }

export interface RegisterOrConnectToPipedriveOrganizationCommand {
  authCode: string;
}

export interface SetupPipedriveOrganizationCommand {
  website: string;
}

export interface UninstallPipedriveOrganizationCommand {
  crmOrganizationId: string;
  crmUserId: string;
}

export enum PipedriveSyncResourceType {
  Person = 'person',
  User = 'user'
}

export interface SyncPipedriveResourceCommand {
  resourceType: PipedriveSyncResourceType;
  crmOrganizationId: string;
  crmResourceId: string;
}

export interface CreateSubscriptionPortalSessionCommand {
  organizationId: string;
  token: string;
}

export interface CreateSubscriptionCheckoutSessionCommand {
  organizationId: string;
  token: string;
  allowPromo: boolean;
}

export interface GetTrackingSettingsQuery {
  organizationId: string;
}

export interface GetTrackingDomainSetupQuery {
  organizationId: string;
  trackingDomainToken: string;
}

export interface TrackingDomainSetupView {
  trackingDomain: TrackingDomainRecord;
  organization: { id: string; name: string; crm: CrmType };
  ipAddress: string;
}

export interface SetupTrackingDomainCommand {
  organizationId: string;
  trackingDomainToken: string;
}

export interface UpdateTrackingSettingsCommand {
  alertFrequencyMinutes: number;
  defaultAlertUserId: string;
}

// -------- Account  -------

// export interface AccountRecord extends OrganizationIdRecord, MutableRecord, DeleteableRecord {}

// -------- User  --------

export enum UserRole {
  Admin = 'admin',
  User = 'user'
}

export interface UserRecord extends MutableRecord, OrganizationIdRecord, DeleteableRecord {
  crmId: string;
  crm: CrmType;
  crmAdmin: boolean;
  isOwner: boolean;
  role: UserRole;
  name: string;
  emailAddress?: string;
  emailDelivery: {
    status: EmailChannelDeliverabilityStatus;
    statusReason: EmailChannelDeliverabilityStatusReason;
    statusAt: Timestamp;
  };
  phone?: string;
}

export interface GetUserSettingsQuery {}

export interface UserSettingsView {
  organization: {
    id: string;
    name: string;
    crm: CrmType;
    subscription: {
      status: SubscriptionStatus;
      trialEndsAt?: IsoDateTimeString;
      createToken: string;
    };
    pipedrive: {
      subdomain: string;
    };
    defaultTrackingDomain: {
      id: string;
      apex: string;
      setupStatus: TrackingDomainSetupStatus;
      token: string;
    };
    tasks: {
      initialSetup: TaskStatus;
    };
  };
  name: string;
  emailAddress?: string;
  emailDelivery: {
    status: EmailChannelDeliverabilityStatus;
    statusReason: EmailChannelDeliverabilityStatusReason;
    statusAt: IsoDateTimeString;
  };
  isOwner: boolean;
  role: UserRole;
}

// -------- Contact  --------
export enum ContactSetupStatus {
  Complete = 'complete',
  Pending = 'pending',
  Failed = 'failed'
}

export interface ContactEmailData {
  value: string;
  hash: string;
}
export interface ContactRecord extends MutableRecord, OrganizationIdRecord, DeleteableRecord {
  crm: CrmType;
  trackingId: string;
  crmId: string;
  crmOwnerId?: string;
  leadDataId?: string;
  token: string;
  firstName: string;
  lastName: string;
  search: string;
  searchLetter: string;
  business?: string;
  crmBusinessId?: string;
  emailHashes: string[];
  emailAddresses: ContactEmailData[];
  phoneNumbers: string[];
  setupStatus: ContactSetupStatus;
  setupAttempts: number; // Must be initialized to index all records
  setupAttemptAt?: Timestamp;
  lastAlertAt: Timestamp;
  alertsEnabled: boolean;
  crmMetadata?: {
    emailsSent: number;
    dealsOpen: number;
    dealsWon: number;
    dealsLost: number;
    notes: number;
    activities: number;
  };
}

export interface ContactDataRecord extends ImmutableRecord {
  organizationId: string;
  contactId: string;
  type: ContactDataType;
  data: string; // Large string, HTML, JSON, No index.
}

export enum ContactDataType {
  LeadInfo = 'lead_info'
}

export interface ContactRowView {
  id?: string;
  crm: CrmType;
  crmId: string;
  token: string;
  firstName: string;
  lastName: string;
  searchLetter: string;
  business?: string;
  crmBusinessId?: string;
  lastAlertAt?: IsoDateTimeString;
  alertsEnabled: boolean;
}

export enum GetContactsQuerySort {
  LastName = 'last_name_asc',
  MostRecentAlert = 'alert_at_desc'
}

export interface ContactRowPagedQueryView extends PagedQueryView<ContactRowView, GetContactsQuerySort> {
  tasks: {
    contactImport: TaskStatus;
    contactSetup: TaskStatus;
  };
}

export interface GetContactsQuery {
  page?: number;
  filter?: string;
  pageSize?: number;
  sort?: GetContactsQuerySort;
}

export interface GetContactActivitySummaryQuery {
  organizationId: string;
  contactId: string;
  contactToken: string;
}

export interface ContactActivitySummaryView {
  organization: {
    id: string;
    name: string;
    timezone: string;
    timezoneName: string;
    crm: CrmType;
    crmBaseUrl: string;
  };
  subscription: {
    status: SubscriptionStatus;
    trialEndsAt?: IsoDateTimeString;
    createToken: string;
  };
  contact: {
    id: string;
    name: string;
    businessName?: string;
    crm: CrmType;
    crmId?: string;
    lastAlertAt?: IsoDateTimeString;
    createdAt: IsoDateTimeString;
  };
  totals: {
    views: number;
    alerts: number;
  };
  dailyViews: {
    start: string;
    end: string;
    records: { day: string; count: number }[];
  };
}

export interface GetContactTrackingActivityHistoryQuery {
  organizationId: string;
  contactId: string;
  contactToken: string;
  startUtc?: IsoDateTimeString;
}

export interface ContactTrackingActivityHistoryView {
  nextStartUtc?: Date;
  hasMore: boolean;
  activity: ContactTrackingActivityDailyHistory[];
}

export interface ContactTrackingActivityDailyHistory {
  day: string;
  domainActivity: ContactTrackingActivityDailyDomainHistory[];
}

export interface ContactTrackingActivityDailyDomainHistory {
  day: string;
  domain: string;
  webActivity: ContactTrackingActivityItem[];
}

export interface ContactTrackingActivityItem {
  id: string;
  type: TrackingActivityType;
  created: IsoDateTimeString;
  device: DeviceType;
  session: string;
  path: string;
  trackingStarted: boolean;
  trackingStartedSource?: { id: string; name: string };
  sessionStarted: boolean;
  triggeredAlert: boolean;
  alertAt?: IsoDateTimeString;
}

export enum CounterType {
  ContactsActiveTotal = 'contacts_active',
  ContactsInactiveTotal = 'contacts_inactive',
  UsersActiveTotal = 'users_active',
  UsersInactiveTotal = 'users_inactive',
  WebViewsTotal = 'web_views',
  WebViewsDay = 'web_views_day',
  UsersActiveDay = 'users_active_day',
  WebViewsMonth = 'web_views_month',
  WebViewsContactTotal = 'web_views_contact',
  WebViewsContactDay = 'web_views_contact_day',
  AlertsTotal = 'alerts_total',
  AlertsDay = 'alerts_day',
  AlertsMonth = 'alerts_month',
  AlertsContactTotal = 'alerts_contact_total',
  PipedriveApiQuotaDay = 'pipedrive_api_quota_day',
  PipedriveWebhooksDay = 'pipedrive_webhooks_day'
}

export interface CounterRecord extends BaseRecord {
  organizationId?: string;
  type: CounterType;
  value: number;
  year?: number;
  month?: number;
  day?: number;
  startsAt?: Timestamp;
  endsAt?: Timestamp;
  contactId?: string;
  contactCrmId?: string;
  siteId?: string;
}

export enum DeviceType {
  Computer = 'c',
  Mobile = 'm',
  Other = 'o'
}
export enum TrackingActivityType {
  WebView = 'web_view',
  WebEvent = 'web_event'
}

export interface TrackingActivityData {
  source: string;
  device: DeviceType;
  domain: string;
  path: string;
  session: string;
  trackingStarted: boolean;
  sessionStarted: boolean;
  ip: string;
  event?: { name: string; value: string };
  alert?: {
    channel: AlertChannel;
    crm?: CrmType;
    pipedrive?: PipedriveAlert;
    createdAt: Timestamp;
  };
}

export interface TrackingActivityRecord extends BaseRecord, OrganizationIdRecord {
  contactId: string;
  contactCrmId?: string;
  type: TrackingActivityType;
  web?: TrackingActivityData;
  createdAt: Timestamp;
}

export enum AlertChannel {
  Crm = 'crm'
}

export enum PipedriveAlertType {
  Activity = 'activity'
}

export interface PipedriveAlert {
  type: PipedriveAlertType;
  crmId: number;
  crmUserId?: number;
}
