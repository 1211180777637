import React, { useContext, useEffect } from 'react';
import { AppContext } from 'App';
import { IonContent, IonSpinner, useIonViewWillEnter, IonPage } from '@ionic/react';
import { useHistory } from 'react-router';

export const SignOutPage: React.FC = () => {
  const appContext = useContext(AppContext);
  const history = useHistory();

  useIonViewWillEnter(async () => {
    appContext.setMenuHidden(true);
    // await appContext.browserStore.remove(BrowserStoreKeys.SelectedOrganizationIdKey);
    if (!appContext.user) {
      history.push('/signin');
      return;
    }
    appContext.auth.logout();
  }, [appContext]);

  useEffect(() => {
    if (!appContext.user) {
      history.push('/signin');
    }
  }, [appContext.user, history]);

  return (
    <IonPage>
      <IonContent className="bg-light">
        <div className="center-content">
          <div style={{ width: '100px', margin: '100px auto' }}>
            <IonSpinner color="danger" />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
