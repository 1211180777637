import React, { useContext, useState } from 'react';
import { IonContent, IonButton, IonIcon, IonCard } from '@ionic/react';
import { AppContext } from 'App';
import { useParams } from 'react-router';
import {
  AppPage,
  AppPageAuthenticationRequirement,
  AppPageSideMenuMode,
  AppPageValidatePermissionsParams
} from 'components/AppPage';
import { ErrorBox } from 'components/ErrorBox';
import { FunctionsApiError } from 'common/firebase/functionsApi';
import {
  CommandType,
  GetTrackingDomainSetupQuery,
  GetUserSettingsQuery,
  QueryType,
  SetupTrackingDomainCommand,
  TrackingDomainSetupView,
  UserSettingsView
} from 'contracts/contracts.shared';
import { gridOutline, checkmarkCircle } from 'ionicons/icons';
import { PageHeaderCenter } from 'components/PageHeaderCenter';

interface SetupTrackingDomainPageParams {
  organizationId: string;
  trackingDomainToken: string;
}

export const SetupTrackingDomainPage: React.FC = () => {
  const appContext = useContext(AppContext);
  const [setupData, setSetupData] = useState<TrackingDomainSetupView>();
  const [errors, setErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const { organizationId, trackingDomainToken } = useParams<SetupTrackingDomainPageParams>();

  const resetForm = () => {
    setErrors([]);
    setIsSubmitting(false);
    setIsVerified(false);
  };

  const onLoad = async () => {
    resetForm();
    setIsSubmitting(true);
    try {
      const setup = await appContext.functions.executeQuery<GetTrackingDomainSetupQuery, TrackingDomainSetupView>(
        QueryType.GetTrackingDomainSetupQuery,
        {
          organizationId,
          trackingDomainToken
        }
      );
      setSetupData(setup);
      setIsSubmitting(false);
    } catch (err) {
      const typedErr = err as FunctionsApiError;
      setErrors(typedErr.errors);
      setIsSubmitting(false);
    }
  };

  const onSubmit = async (e: React.MouseEvent<HTMLIonButtonElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await appContext.functions.executeCommand<SetupTrackingDomainCommand, boolean>(
        CommandType.SetupTrackingDomainCommand,
        {
          organizationId,
          trackingDomainToken
        }
      );
      setIsVerified(true);

      if (appContext.user) {
        const userSettings = await appContext.functions.executeQuery<GetUserSettingsQuery, UserSettingsView>(
          QueryType.GetUserSettingsQuery,
          {}
        );
        appContext.setUserSettings(userSettings);

        // setTimeout(() => {
        //   setIsSubmitting(false);
        //   history.push('/dashboard');
        // }, 1000);
      }

      setIsSubmitting(false);
    } catch (err) {
      const typedErr = err as FunctionsApiError;
      setErrors(typedErr.errors);
      setIsSubmitting(false);
    }
  };

  const onValidatePermissions = (request: AppPageValidatePermissionsParams): boolean => {
    return true;
  };

  const trackingCode = (host: string) => `<script>
window.__content=window.__content||function(){
(__content.cmd=__content.cmd||[]).push(arguments);};
__content("source","${host}");__content("page");
</script> 
<script async src="https://${host}/js.js"></script>`;

  return (
    <AppPage
      appContext={appContext}
      sideMenuMode={AppPageSideMenuMode.Hide}
      authenticationRequirement={AppPageAuthenticationRequirement.Any}
      onLoad={onLoad}
      onValidatePermissions={onValidatePermissions}
      isLoading={isSubmitting}
    >
      <IonContent>
        {setupData && (
          <>
            <PageHeaderCenter
              organizationName={setupData.organization.name}
              buttons={
                appContext.userSettings ? (
                  <>
                    <IonButton slot="end" href={`${process.env.REACT_APP_BASE_URL}/dashboard`} size="small">
                      <IonIcon icon={gridOutline} slot="icon-only" />
                    </IonButton>
                  </>
                ) : null
              }
            />

            <div className="vertical-layout vertical-layout-compressed">
              <IonCard className="page-content" style={{ margin: '0 auto', maxWidth: '600px', minHeight: '500px' }}>
                <div className="panel panel-padded">
                  <div className="panel" style={{ width: '95%', margin: '0 auto' }}>
                    <h5 className="text-subheading-underline">Setup Turbocharger for Pipedrive Tracking</h5>

                    <p className="text-light-weight text-md">
                      Three steps are necessary to start tracking your Pipedrive contacts. The link to this page can be
                      shared with your IT department. Need help? Email turbo@turbochargerhq.com.
                    </p>
                    <ErrorBox errors={errors} onClearErrors={() => setErrors([])} />
                    {setupData && (
                      <>
                        <br />
                        <div>
                          <strong className="color-dark">
                            <u>Step 1:</u> Create DNS Record
                          </strong>
                          <br />
                        </div>
                        <p className="text-md">
                          {setupData.trackingDomain.id}&nbsp; &nbsp; {setupData.ipAddress} (A Record)
                          <br />
                          <br />
                        </p>

                        <div>
                          <strong className="color-dark">
                            <u>Step 2:</u> Verify
                          </strong>
                          <br />
                        </div>
                        <div className="text-light-weight text-md">
                          Verify your DNS configuration is correct before adding code to your website(s). <br />
                          <br />
                          Note: After verification, our system will automatically setup your dedicated tracking website
                          and assign a new SSL certificate. It may take a few minutes for the tracking site to respond
                          correctly for SSL requests.
                          <br /> <br />
                          <IonButton
                            color="danger"
                            fill="outline"
                            className="ion-margin-bottom"
                            onClick={onSubmit}
                            disabled={isSubmitting}
                          >
                            Verify DNS &gt;
                          </IonButton>
                          {isVerified && (
                            <div>
                              <IonIcon icon={checkmarkCircle}></IonIcon> <strong>Success</strong>
                              <br />
                              DNS Verification completed.
                            </div>
                          )}
                          <br />
                          <br />
                        </div>
                        <div>
                          <strong className="color-dark">
                            <u>Step 3:</u> Copy and Paste Website Tracking Code
                          </strong>
                          <br />
                        </div>
                        <p className="text-light-weight text-md">
                          Copy and paste the code below right before the {'</body>'} tag on any websites you want to
                          track.
                          <br /> <br />
                          Important: Tracking will only work for {setupData.trackingDomain.apex + ' '} or it&#39;s
                          subdomains.
                          <br />
                        </p>
                        <p className="text-sm">
                          <textarea
                            readOnly={true}
                            style={{ width: '100%', height: '120px', border: 'solid 1px #eeeeee' }}
                            value={trackingCode(setupData.trackingDomain.id)}
                          ></textarea>
                          <br />
                          <br />
                        </p>
                        <br />
                        <br />
                        <p className="text-sm">You can close this page when you are finished.</p>
                      </>
                    )}
                  </div>
                </div>
              </IonCard>
            </div>
          </>
        )}
      </IonContent>
    </AppPage>
  );
};
