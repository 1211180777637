import React, { useContext, useEffect, useState } from 'react';
import { IonContent, IonButton, IonInput, IonItem, IonLabel, IonList, IonLoading, IonIcon } from '@ionic/react';

import { AppContext } from 'App';
import { useHistory, useLocation } from 'react-router';
import {
  AppPage,
  AppPageAuthenticationRequirement,
  AppPageSideMenuMode,
  AppPageValidatePermissionsParams
} from 'components/AppPage';
import { ErrorBox } from 'components/ErrorBox';
import 'firebase/auth';
import { keyOutline } from 'ionicons/icons';
import { useCookies } from 'react-cookie';
import { GetAccountOwnerAuthToken, QueryType } from 'contracts/contracts.shared';

export const SignInPage: React.FC = () => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['redirect']);

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setErrors([]);
    setIsSubmitting(false);
  };

  const onLoad = async () => {
    resetForm();

    if (!appContext.user) {
      const r = new URLSearchParams(location.search).get('r');
      if (r) {
        setCookie('redirect', r, { sameSite: 'lax', path: '/' });
      }
    }
  };

  // Redirects after appContext.auth.login async event
  useEffect(() => {
    if (appContext.user) {
      resetForm();
      history.push('/dashboard');
    }
  }, [appContext.user, history]);

  const onLogin = async (e: React.MouseEvent<HTMLIonButtonElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await appContext.functions.executeQuery<GetAccountOwnerAuthToken, string>(
        QueryType.GetAccountOwnerAuthTokenQuery,
        {
          organizationId: email,
          credential: password
        }
      );

      if (!response) {
        setErrors([
          'Invalid Username/Password',
          'If your company uses Pipedrive, use the button below to sign in with Pipedrive instead.'
        ]);
      } else {
        appContext.auth.auth.signInWithCustomToken(response);
      }
    } catch (ex) {
      setErrors(['An unexpected error occurred. Please try agin.']);
    }
    setIsSubmitting(false);
  };

  const onValidatePermissions = (request: AppPageValidatePermissionsParams): boolean => {
    return true;
  };

  return (
    <AppPage
      appContext={appContext}
      sideMenuMode={AppPageSideMenuMode.Hide}
      authenticationRequirement={AppPageAuthenticationRequirement.UnAuthenticated}
      onLoad={onLoad}
      onValidatePermissions={onValidatePermissions}
    >
      <IonContent>
        <div className="vertical-layout bg-light">
          <h2 className="center-text">
            <img className="turbocharger-logo" alt="Logo" src={'/assets/logo-turbocharger.png'} />
          </h2>

          <div className="form-container">
            <p className="slogan-header center-text">
              <strong>Sign in to your account.</strong>
            </p>
            <br />

            <form>
              <ErrorBox errors={errors} onClearErrors={() => setErrors([])} />
              <IonList>
                <IonItem className="no-padding-start inner-padding-start ">
                  <IonLabel position="floating">Email Address</IonLabel>
                  <IonInput
                    name="email"
                    type="text"
                    value={email}
                    onIonChange={(e) => setEmail((e.target as HTMLInputElement)?.value)}
                  />
                </IonItem>
                <IonItem className="no-padding-start inner-padding-start ">
                  <IonLabel position="floating">Password</IonLabel>
                  <IonInput
                    name="password"
                    type="password"
                    value={password}
                    onIonChange={(e) => setPassword((e.target as HTMLInputElement)?.value)}
                  />
                </IonItem>
              </IonList>

              <br />

              <IonButton
                expand="block"
                color="danger"
                fill="solid"
                className="ion-margin-bottom"
                onClick={onLogin}
                disabled={isSubmitting}
              >
                Sign In
              </IonButton>

              {/* <IonButton
                expand="block"
                fill="clear"
                color="dark"
                className="ion-margin-bottom text-weight-normal "
                routerLink="/password/forgot"
                disabled={isSubmitting}
              >
                Forgot Password?
              </IonButton> */}
              <p className="slogan slogan-size center-text">OR</p>

              <IonButton
                expand="block"
                fill="outline"
                color="danger"
                className="ion-margin-bottom ion-margin-top"
                href={process.env.REACT_APP_PIPEDRIVE_LOGIN_URL}
              >
                <IonIcon slot="start" icon={keyOutline} size="small" color="danger" />
                Sign In with Pipedrive
              </IonButton>
              <br />
            </form>
          </div>
        </div>

        <IonLoading isOpen={isSubmitting} spinner="dots" cssClass="custom-loading" />
      </IonContent>
    </AppPage>
  );
};
