import React from 'react';
import { IonHeader, IonToolbar, IonImg, IonText } from '@ionic/react';

export const PageHeaderCenter: React.FC<{ organizationName?: string; buttons?: JSX.Element | JSX.Element[] }> = ({
  organizationName,
  buttons
}) => {
  return (
    <>
      <IonHeader className="main-header">
        <IonToolbar color="warning" style={{ height: '5px' }}></IonToolbar>
        <IonToolbar color="primary">
          <IonToolbar color="primary" className="toolbar-inner">
            <IonImg
              alt="Logo"
              className="turbocharger-logo"
              src={'/assets/logo-turbocharger-w.png'}
              slot="start"
              style={{ marginRight: '10px' }}
            />

            <IonText className="header-organization">{organizationName}</IonText>

            {buttons}

            <br />
          </IonToolbar>
        </IonToolbar>
      </IonHeader>
    </>
  );
};
