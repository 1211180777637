import React, { useContext, useEffect, useState } from 'react';
import { IonButton, IonContent, IonSpinner } from '@ionic/react';
import { AppContext } from 'App';
import { useHistory, useLocation } from 'react-router';
import {
  AppPage,
  AppPageAuthenticationRequirement,
  AppPageSideMenuMode,
  AppPageValidatePermissionsParams
} from 'components/AppPage';
import { FunctionsApiError } from 'common/firebase/functionsApi';
import { RegisterOrConnectToPipedriveOrganizationCommand, CommandType } from 'contracts/contracts.shared';
import { ErrorBox } from 'components/ErrorBox';
import { useCookies } from 'react-cookie';
import { isNullOrUndefinedOrEmpty } from 'common/helpers';

export const PipedriveConnectPage: React.FC = () => {
  const appContext = useContext(AppContext);
  const [errors, setErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['redirect']);
  // Redirects after appContext.auth.login async event
  useEffect(() => {
    if (appContext.user) {
      resetForm();
      let redirect = '/dashboard';
      if (!isNullOrUndefinedOrEmpty(cookies.redirect)) {
        redirect = decodeURI(cookies.redirect);
        removeCookie('redirect', { path: '/' });
      }
      history.push(redirect);
    }
  }, [appContext.user, history]);

  const resetForm = () => {
    setErrors([]);
    setIsSubmitting(false);
  };

  const onLoad = async () => {
    setIsSubmitting(true);
    if (appContext.user) {
      await appContext.auth.logout();
    }
    try {
      const code = new URLSearchParams(location.search).get('code');
      if (!code || code === 'undefined') {
        setErrors(['The authorization code required to connect to Pipedrive is invalid. Please try again.']);
        setIsSubmitting(false);
        return;
      }
      const response = await appContext.functions.executeCommand<
        RegisterOrConnectToPipedriveOrganizationCommand,
        string
      >(CommandType.RegisterOrConnectToPipedriveOrganizationCommand, { authCode: code as string });
      appContext.auth.auth.signInWithCustomToken(response);
    } catch (err) {
      const typedErr = err as FunctionsApiError;
      setErrors(typedErr.errors);
      setIsSubmitting(false);
    }
  };

  const onValidatePermissions = (request: AppPageValidatePermissionsParams): boolean => {
    return true;
  };
  const spinnerStyle = { width: '32px', margin: '100px auto' };
  return (
    <AppPage
      appContext={appContext}
      sideMenuMode={AppPageSideMenuMode.Hide}
      authenticationRequirement={AppPageAuthenticationRequirement.UnAuthenticated}
      onLoad={onLoad}
      onValidatePermissions={onValidatePermissions}
      isLoading={isSubmitting}
      hideLoadingSpinner={true}
    >
      <IonContent>
        <div className="vertical-layout bg-light">
          <h2 className="center-text">
            <a href="/">
              <img className="turbocharger-logo" alt="Logo" src={'/assets/logo-turbocharger.png'} />
            </a>
          </h2>
          <div className="form-container">
            <p className="slogan-header center-text">{/* <strong>Setting Up Your Free Trial</strong> */}</p>
            <p className="slogan-header center-text text-xs">Connecting to Your Pipedrive Account</p>
            <div className="center-content">
              <ErrorBox errors={errors} onClearErrors={() => setErrors([])} clearDisabled={true} />
              {errors?.length > 0 && (
                <div style={{ margin: '15px auto' }}>
                  <br />
                  <IonButton expand="full" color="danger" routerLink="/signin" className="center-content">
                    Start Over
                  </IonButton>
                </div>
              )}
              {errors?.length === 0 && (
                <div style={spinnerStyle}>
                  <IonSpinner color="danger" />
                </div>
              )}
            </div>
          </div>
        </div>
      </IonContent>
    </AppPage>
  );
};
