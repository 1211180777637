import firebase from 'firebase/app';
import 'firebase/auth';

export class AuthApi {
  // Notes:
  // 1) Must wrap calls in promises to to fb library
  // See https://github.com/firebase/firebase-js-sdk/issues/1881
  public auth: firebase.auth.Auth;
  constructor(fbAuth: firebase.auth.Auth) {
    this.auth = fbAuth;
  }
  public async login(username: string, password: string): Promise<firebase.auth.UserCredential> {
    return new Promise((resolve, reject) => {
      return this.auth
        .signInWithEmailAndPassword(username, password)
        .then((user) => resolve(user))
        .catch((err) => reject(err));
    });
  }
  public async logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      return this.auth
        .signOut()
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }
}
